.avisoAdblock{
    position: absolute;
    bottom: 1em;
    right: 1em;
    background-color: gold;
    border-radius: 1em;
    padding: 0.4em;
    opacity: 0.6;
    z-index: 999;
    transition: opacity 0.25s;

    *{
        cursor: default;
    }

    md-icon{
        cursor: pointer;
    }
}

.avisoAdblock:hover{
    opacity: 1;    
}

#publicidad {
    .toggler {
        cursor: pointer;
    }

    .listaArchivos {
        .elemento {
            border-radius: 4px;
            margin: 4px;
            position: relative;
            width: 150px;
            padding: 4px;
            background: lightgray;

            > div {
                word-wrap: break-word;
                text-align: center;
            }
        }

        img, video {
            width: 150px;
            height: 150px;
            object-fit: contain;
        }

        .icon-video {
            position: absolute;
            top: 0;
            left: 0;
        }

        .icon-close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    .listaImgs{
        & > div {
            margin: 8px;
            position: relative;
            width: 200px;
        }

        .elemento{
            position: relative;
            width: 200px;
            height: 200px;

            .imagen{
                max-width: 100%;
                max-height: 100%;
            }
            
            a {
                align-self: flex-end;
                pointer-events: none;
            }

            .overlay{
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                right:0;
                background:rgba(100,100,100,0.4);
                margin:0;

                .zoom{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    padding: 3px;
                    border-top-right-radius: 10px; 
                    background: white;

                    cursor: pointer;                        
                }

                .borrar{
                    position: absolute;
                    top:0;
                    right: 0;
                    padding: 3px;
                    border-bottom-left-radius: 10px; 
                    background: white;

                    cursor: pointer;
                }

                .url{
                    position: absolute;
                    bottom:0;
                    right: 0;
                    padding: 3px;
                    border-top-left-radius: 10px; 
                    background: white;

                    cursor: pointer;
                }

                display:none;
            }

            .check{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                font-size: 50px;
                height: 50px;
                width: 50px;
                line-height: 50px;
                cursor: pointer;
            }

            .selected{
                color: green;
            }

            .noSelected{
                color:gray;
            }

            .top-left-icons{
                position: absolute;
                top: 0;
                left: 0;

                &, md-icon {
                    font-size: 30px;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    margin-right: 6px;
                }
            }
        }

        .sites-container {
            margin-top: 8px;

            .text-boxed-light {
                margin: 2px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .elemento:hover .overlay{
            display: block;
        }

        .div-selected{
            border:6px solid green;
        }
    }

    .uploadContainer{
        border: 2px solid lightgrey;
        border-radius: 10px;
    }

    .fileData{
        border: 2px solid lightgrey;
        border-radius: 10px;

        img{
            max-height: 90px;
            max-width: 90px;
        }
    }

    legend{
        padding: 0 10px;
    }
}

#grupos-banners{
    .group-container {
        margin: 10px 0;
        border: 1px solid black;
        padding-left: 12px;
        padding-bottom: 12px;
        border-radius: 2px;
        background: lightgray;
    }

    .groupContainer{
        border:1px solid black;
        margin: 10px;
        min-width: 270px;

        .groupHeader{
            padding: 5px 0 5px 20px;
            background-color: black;
            color: white;
            font-size: 1.6rem;
        }

        .groupBody{
            padding: 10px;
            max-height: 15em;
            position: relative;
            overflow: auto;

            md-checkbox, md-checkbox:last-of-type{
                margin:3px;
            }

            .d{
                /*padding-left: 1em;*/
                font-size: 1.3rem;
                font-style: italic;
            }
        }
    }

    md-radio-group{
        md-radio-group{
            margin: 0 20px;
        }

        md-radio-button{
            height: auto;
        }
    }

    .md-icon-button{
        margin: 0;
        padding: 0;
        height: auto;

        .icon{
            color: whitesmoke;
        }
    }
}

#sin-grupo-container{
    span{
        /*background: lightcoral;*/
        margin: 4px 8px;
        padding: 4px 8px;
        border-radius: 1em;
        font-size: 16px;
    }
}

#setLink-banner {

    .text-boxed {
        white-space: normal;
    }

    .bold {
        font-weight: bold;
        border-radius: 2px;
        padding: 4px 8px;
        background-color: #fbf492;
    }

    label {
        color: rgba(0,0,0,0.54);
    }

    .padding-horizontal-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    md-sidenav {
        height: 100%;
        max-width: none;
        width: 70%;

        md-content {
            overflow: visible;
            /*height: calc(100% - $toolbar-height)*/
        }

        md-checkbox {
            margin: 8px 0;

            span {
                white-space: nowrap;
                /*width: 120px;*/
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }

        .first-letter-style{
            span:first-letter {
                font-weight: bold;
                font-size: 180%;
                color: #ff5722;
                line-height: 1;
            }
        }
    }
}

#publicidad-ayuda {
    md-icon {
        font-size: 30px;
        height: 30px;
        width: 30px;
        line-height: 30px;
        margin-right: 12px;
    }

    .selected{
        color: green;
    }

    .noSelected{
        color: gray;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #publicidad {
        .contenedorImgs{ 
            .listaImgs{
                .elemento{
                    .overlay{
                        display:block;
                    }
                }
            }
        }
    }
}