#gruposStb{

    .md-2-line{
        padding-left: 20px;
        margin:10px 0;
        border: 1px solid lightgray;

        .md-list-item-text span{
            color: lightslategray;
        }
    }

    .md-2-line:hover{
        background-color: rgba(255,0,0,0.35);

        background: -webkit-linear-gradient(left, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(255,0,0,0.35), rgba(0,0,0,0));

        .md-list-item-text span{
            color: initial;
        }
    }

    .stb-container{
        margin: 4px 8px;
        padding: 2px;
        border-radius: 2em;
        background: lightgray;
        cursor: pointer;

        .icon-checkbox-blank-circle{
            color: darkgray;
        }

        span{
            margin: 0 8px 0 2px;
        }
    }

    .checked {
        background: green;

        .icon-checkbox-marked-circle{
            color: limegreen;
        }
    }

    .range-ini {
        position: relative;
        background: yellow;

        &:before {
            content: ">";
            position: absolute;
            left: -8px;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #gruposStb{
        md-dialog-content{
            padding: 8px 2px 0 2px;
        }
    }
}