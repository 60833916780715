#dhcpEquipos{

    .content {

        .form-wrapper {

        }

        .form-data-wrapper {
            margin-left: 16px;

            .json-data-wrapper {
                overflow: hidden;

                pre {
                    font-size: 16px;
                }
            }
        }
    }
    
    md-input-container{
        border: 1px solid lightgray;
        border-radius: 5px;


        textarea{
            min-height: calc(100vh - 258px);
            overflow-y: auto;
            line-height: normal;
        }
    }
}