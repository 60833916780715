#precios{
    h3{
        margin: 0;
        padding: 0.5em;
    }

    .pkg{
        border: 1px solid black;
        margin: 10px;

        .pkgTitle{
            background: black;
            color: white;
            position:relative;

            h3{
                margin: 0;
                padding: 0.5em;
            }

            img{
                max-height: 50px;
                margin: 2px 1em;
            }

            md-icon{
                position: absolute;
                top: 0;
                right: 0;
                color:limegreen;
            }
        }

        ul{
            li{
                *{
                    margin: 10px 0;
                }
            }
        }
    }

    .srv{
        border: 1px solid black;
        margin: 10px;
        min-width:200px;

        .srvTitle{
            position:relative;

            h3,h4{
                margin: 0;
                padding: 0.5em;
            }

            img{
                max-height: 30px;
                margin: 2px;
            }
        }
    }
}