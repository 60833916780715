#traduccion{
    md-tab-content {
        background: #ffffff61;
        padding-top: 12px;
        overflow: auto;
    }
    
    table{
        border-collapse: collapse;

        td,th {
            border-bottom: 1px solid gray;
            
            md-icon {
                cursor: pointer;
            }
        }
    }

    img {
        max-width: 50px;
        max-height: 50px;
    }

    textarea {
        min-height: 100px;
        resize: vertical;
        margin-top: 2px;
        margin-bottom: 4px;
    }

    input, textarea {
        width: 160px;
    }
    
    .icon-content-copy {
        color: #b90000;
    }
    
    .icon-content-save {
        color: darkblue;
    }
    
    .icon-format-text {
        color: darkgreen;
    }
}