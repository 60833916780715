#temas{
    .fileData{
        border: 2px solid lightgrey;
        border-radius: 10px;

        img{
            max-height: 90px;
            max-width: 90px;
        }
    }

    .tema{
        margin: 1em;
        border-radius: 1em 3em 3em 1em;

        transition: box-shadow 0.25s;

        h2{
            margin-right: 3px;
            margin-left: 8px;
            min-width: 120px;
        }
    }

    .tema:hover{
        box-shadow: 5px 5px 5px #aaaaaa;
    }
}