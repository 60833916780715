#importexport {
    .canal-list-item{
        min-width: 280px;
        transition: background 0.25s;
        background: lightgray;
        border-radius: 1em;
    }

    .disabled{
        background: gray;
        text-decoration:line-through;
    }
    
    .md-2-line, .md-3-line{
        margin: 1px;
        min-height: 0;
    }

    .iconoCanal{
        min-width: 50px;
        min-height: 50px;
        margin: 4px 8px;
        z-index: 0;

        img{
            max-height: 50px;
            max-width: 50px;
        }
    }

    md-list-item{
        .iconSelected{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100px;
            font-size: 50px;
            height: 50px;
            line-height: 50px;
            color: royalblue;
        }
        
        button{
            min-height: 0;
            padding-left: 0;
            background: transparent;
        }
    }

    .pantallaCarga{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(150,150,150,0.9);
        text-align: center;

        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        h1, h3{
            color: #000;
        }
    }

    .normal,.high, .critical, .adv, .alarmas, .noAlarmas{
        border-radius: 15px;
        padding: 5px;
        margin: 5px;

        md-icon{
            margin-right: 15px;
        }
    }

    .adv{        
        background-color: #fefdbe;

        background: -webkit-linear-gradient(left, rgba(254,253,190,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(254,253,190,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(254,253,190,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(254,253,190,1), rgba(0,0,0,0));
    }

    .normal{
        background-color: #87ff8b;

        background: -webkit-linear-gradient(left, rgba(135,255,139,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(135,255,139,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(135,255,139,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(135,255,139,1), rgba(0,0,0,0));
    }

    .high{
        background-color: #fdef4c;

        background: -webkit-linear-gradient(left, rgba(253,239,76,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(253,239,76,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(253,239,76,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(253,239,76,1), rgba(0,0,0,0));
    }

    .critical{
        background-color: #fd6464;

        background: -webkit-linear-gradient(left, rgba(253,100,100,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(253,100,100,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(253,100,100,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(253,100,100,1), rgba(0,0,0,0));
    }

    .alarmas{
        background-color: #ffc621;

        background: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(255,198,33,1));
        background: -o-linear-gradient(right, rgba(0,0,0,0), rgba(255,198,33,1));
        background: -moz-linear-gradient(right, rgba(0,0,0,0), rgba(255,198,33,1));
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(255,198,33,1));

        max-height: 32px;
        overflow-y: hidden;
        transition: max-height 0.5s;
    }

    .alarmas:hover{
        max-height: 180px;
    }

    .noAlarmas{
        background-color: #87ff8b;

        background: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(135,255,139,1));
        background: -o-linear-gradient(right, rgba(0,0,0,0), rgba(135,255,139,1));
        background: -moz-linear-gradient(right, rgba(0,0,0,0), rgba(135,255,139,1));
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(135,255,139,1));
    }
}