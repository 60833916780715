/*----------------------------------------------------------------*/
/*  Template Layouts
/*----------------------------------------------------------------*/

// Global
html,
body {
    overflow: hidden !important;
}

#main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

// Boxed Layout
body {

    &.boxed {
        background: #3F4450;

        #main {
            overflow: hidden !important;
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.45);
        }
    }
}

// Content only
#layout-content-only {
    position: relative;
    height: 100%;
    max-height: 100%;

    #content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

// Content with toolbar
#layout-content-with-toolbar {
    position: relative;
    height: 100%;
    max-height: 100%;

    #content {
        position: absolute;
        top: $toolbarHeight;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

// Vertical navigation
#layout-vertical-navigation {
    height: 100%;
    max-height: 100%;

    #content-container {
        position: relative;
        overflow: hidden;

        #content {
            /*            position: absolute;
                        top: calc(#{$toolbarHeight} + #{$secondToolbarHeight});*/
            /*top: 0px;*/
            /*            right: 0;
                        bottom: 0;
                        left: 0;*/
        }

        //Pantalla de carga única
        #pantallaCarga{
            position: absolute;
            top: $toolbarHeight;
            bottom: 0;
            left: 0;
            right: 0;
            /*background-color: rgba(150,150,150,1);*/
            background: -webkit-radial-gradient(circle, rgb(100,100,100), rgb(160,160,160));
            background: -o-radial-gradient(circle, rgb(100,100,100), rgb(160,160,160));
            background: -moz-radial-gradient(circle, rgb(100,100,100), rgb(160,160,160));
            background: radial-gradient(circle, rgb(100,100,100), rgb(160,160,160));

            .content{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            h1, h3{
                color: #000;
                text-align: center;
            }
        }

        .lsHidden{
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.5s;
        }
    }

    #expire-license-message {
        position: fixed;
        z-index: 99999;
        padding: 8px 4px 8px 16px;
        font-size: 24px;
        background: #ffff82e0;
        width: fit-content;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 8px;
        border-radius: 8px;
        
        .md-button {
            margin-right: 0;
            margin-left: 16px;
        }
    }
}

// Horizontal navigation
#layout-horizontal-navigation {
    height: 100%;
    max-height: 100%;

    #content-container {
        position: relative;
        overflow: hidden;

        #content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}