#lotes{
    .md-3-line{
        padding-left: 20px;
        margin:10px 0;
        border: 1px solid lightgray;
    }

    .md-3-line:hover{
        background-color: rgba(255,0,0,0.35);

        background: -webkit-linear-gradient(left, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(255,0,0,0.35), rgba(0,0,0,0));
    }

    md-radio-group{
        md-radio-group{
            margin: 0 20px;
        }
        
        md-radio-button{
            height: auto;
        }
    }

    .groupContainer{
        border:1px solid black;
        margin: 10px;
        min-width: 270px;

        .groupHeader{
            padding: 5px 0 5px 20px;
            background-color: black;
            color: white;
        }

        .groupBody{
            padding: 10px;
            max-height: 15em;
            position: relative;
            overflow: auto;

            md-checkbox, md-checkbox:last-of-type{
                margin:3px;
            }
            
            >div {
                display: block;
                height: 23px;
                margin: 3px;
            }

            .d{
                /*padding-left: 1em;*/
                font-size: 1.3rem;
                font-style: italic;
            }
        }
    }

    .md-icon-button{
        margin: 0;
        padding: 0;
        height: auto;

        .icon{
            color: whitesmoke;
        }
    }
    
    .stb-filter-form {
        border-collapse: collapse;
        
        span {
            width: 50%;
        }
        
        .icon-eye {
            cursor: pointer;
            
            &:hover {
                color: blue;
            }
        }
    }
    
    .patch-attr {
        width: 100%;
        
        md-checkbox {
            margin: 6px 0;
        }
        
        td {
            min-width: 50px;
            
            &:nth-child(2) {
                background: lightgray;
                padding: 2px 8px;
            }
        }
        
        md-select {
            margin: 0;
        }
    }
    
    .progress-log-container {
        border: 1px solid;
        margin-bottom: 1em;
        padding: 0;
        
        .progress-log-container-header {
            p {
                margin-left: 4px;
            }
            
/*            md-icon {
                color: whitesmoke;
                margin: 10px 4px;
                cursor: pointer;
                
                &:hover {
                    color: yellow;
                }
            }*/
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    
    #lotes {        
        form{
            padding: 0;
        }
    }
}