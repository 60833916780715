#listadoAvisos {
    input {
        background-color: #f7f7f7;
    }

    md-badge{
        margin: 0 0 0 8px;
        /*background-color: #4288bc;*/
        color: white;
        padding: 3px 6px;
        border-radius: 4px;
    }

    td{
        padding-top: 0;
        padding-bottom: 0;
    }

    .Enviado{
        background-color: #cdcdcd;
        color: #7c7c7c;
    }

    .Enviado:hover{
        background-color: #b9b9b9;
    }

    .NoEnviado{
        background-color: #9dc7fd;    
    }

    .NoEnviado:hover{
        background-color: #7092be;    
    }

    .pantallaCarga{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(150,150,150,1);

        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        h1, h3{
            color: #000;
        }
    }

    .positioner{
        position: relative;

        >.md-button{
            position: absolute;
            z-index:7;
        }
    }

    .type-selector {
        > div {
            padding: 8px;
            cursor: pointer;

            &:hover:not(.md-warn-bg) {
                background: lightgray;
            }
        }
    }

    md-list-item {
        &:hover {
            background: lightgray;
        }

        .md-avatar {
            border-radius: 0;
        }

        .md-list-item-text {
            * {
                white-space: normal;
            }
        }
    }

    /* Buzz */
    @-webkit-keyframes hvr-buzz {
        50% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        100% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
    }
    @keyframes hvr-buzz {
        50% {
            -webkit-transform: translateX(3px) rotate(2deg);
            transform: translateX(3px) rotate(2deg);
        }
        100% {
            -webkit-transform: translateX(-3px) rotate(-2deg);
            transform: translateX(-3px) rotate(-2deg);
        }
    }
    .hvr-buzz {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .hvr-buzz:hover, .hvr-buzz:focus, .hvr-buzz:active {
        -webkit-animation-name: hvr-buzz;
        animation-name: hvr-buzz;
        -webkit-animation-duration: 0.15s;
        animation-duration: 0.15s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    .md-subheader {
        .md-button {
            margin: 0;
            padding: 0;
            width: 30px;
            height: 30px;

            &:not(:last-of-type) {
                margin-right: 12px;
            }
        }
    }
}

#aviso-details {
    .strong{
        font-weight: bold;
    }

    h2 {
        margin-top: 0;
    }

    .image-container {
        padding: 8px;
        border-radius: 5px;
        background: #252525;
        margin-right: 8px;

        img {
            max-height: 100px;
            max-width: 100px;
        }
    }

    .room-container {
        background: lightgray;
        margin: 4px;
        padding: 2px 8px;
        border-radius: 2em;
    }
}

#enviarAviso {
    padding: 0;

    .tipo-aviso{
        img {
            max-width: 320px;
        }
    }

    input.ng-invalid, textarea.ng-invalid{
        background-color: pink;
    }

    input.ng-valid, textarea.ng-valid{
        background-color: lightgreen;
    }

    input.ng-pristine, textarea.ng-pristine, md-select, md-datepicker-input-container{
        background-color: #f7f7f7; 
    }

    input, textarea, md-select{
        transition:background-color 0.5s;
    }

    .pantallaCarga{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(150,150,150,0.7);

        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        h1, h3{
            color: #000;
        }
    }

    .selector{
        background-color: black;
        height: 80px;
        max-width: 150px;
    }

    md-input-container img{
        margin-right: 20px;
        background-color: black;
        max-height: 100px;
        max-width: 100px;
        padding: 10px;
        border-radius: 1em;
        min-height: 100px;
        min-width: 100px;
    }

    form{
        margin:0 2em;

        md-checkbox{
            margin:5px 0;
        }
    }

    .temporizacion{
        input, md-datepicker, md-select{
            background-color: rgba(255, 255, 255, 0.3)
        }
    }

    .info-temp {
        padding: 4px;
        border-radius: 8px;
        font-size: 14px;
    }

    .groupContainer{
        border:1px solid black;
        margin: 10px;
        min-width: 270px;

        .groupHeader{
            padding: 5px 0 5px 20px;
            background-color: black;
            color: white;
        }

        .groupBody{
            padding: 10px;
            max-height: 15em;
            position: relative;
            overflow: auto;
            
            > div {
                display: block !important;
            }
            
            md-checkbox, md-checkbox:last-of-type{
                margin:3px;
            }

            .d{
                /*padding-left: 1em;*/
                font-size: 1.3rem;
                font-style: italic;
            }

            .md-avatar {
                height: 29px;
                width: 29px;
                object-fit: contain;
                margin-left: auto;
            }
        }

        .md-icon-button{
            margin: 0;
            padding: 0;
            height: auto;

            .icon{
                color: whitesmoke;
            }
        }
    }

    .envioInfo{
        background: yellow;
        padding: 0.5em;
        text-align: center;
        border-radius: 1em;
    }

    .container {
        border: 1px solid gray;
        margin-bottom: 8px;
        padding: 4px;
        border-radius: 8px;
    }

    .button-container {
        &:nth-child(even) {
            background-color: lightgray;
            margin: 0 -4px;
            padding: 2px 6px;
            border-bottom: 1px solid grey;
            border-top: 1px solid grey;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #enviarAviso {
        md-input-container img{
            margin-right: 0;
        }

        form{
            margin: 0;
        }
    }
}

#elegirImagenNotif{

    md-dialog-content{
        padding: 12px;
    }

    img{
        cursor: pointer;
        border: 4px solid transparent;
        margin: 6px;
        background-color: black;
        max-height: 80px;
        max-width: 80px;
        padding: 4px;
        border-radius: 1em;
        min-height: 80px;
        min-width: 80px;
    }

    img:hover{
        border:4px solid red;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #listadoAvisos {
        md-list-item {
            > button {
                padding: 0;
            }
        }
    }

}