#recaudacion{
    md-list{
        .md-list-item-text{
            margin:7px;
        }
        md-list-item{
            min-height: 0px;
        }
        md-list-item:hover{
            background-color: lightgray;
        }
    }

    md-datepicker{
        margin-right: 0;
        padding-right: 0;
        background-color: transparent;
    }

    .md-1-line{
        h3{
            margin: auto;
        }
    }

    .total{
        *{
            font-weight: bolder;
        }
    }

    table {
        width: 45%;
        margin-bottom: 1em;
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    th, td {
        padding: 5px;
        text-align: center;
    }
    table tr:nth-child(even) {
        background-color: #eee;
    }
    table tr:nth-child(odd) {
        background-color:#fff;
    }
    table tr:last-child{
        background-color: #8a8a8a;
    }

    .specialTd{
        cursor: pointer;
    }

    .specialTd:hover{
        background-color: lightskyblue;
    }

    ._c1,._c2,._c5,._c10,._c20,._c50,._c100,._c200{
        background-color: goldenrod;
        border-radius: 1em;
        min-width: 20px;
        min-height: 20px;
        text-align: center;
        line-height: 20px
    }

    ._b5,._b10,._b20,._b50,._b100,._b200,._b500{
        background-color: lightgreen;
        min-width: 20px;
        text-align: center;
    }

    .flex-12{
        margin: 2px 6px;
    }
    
    .odd{
        background-color: lightgrey;
    }
    
    .operation{
        min-height: 28px;
        position: relative;
        
        > md-icon{
            position: absolute;
            top: 0;
            right: 0;
            
            font-size:28px;
            height: 28px;
            width: 28px;
            line-height: 28px;
        }
    }
}

.md-datepicker-input-mask-opaque{
    background-color: transparent;
}