/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/Quantion/auth/errores/401/error-401.scss";
@import "main/Quantion/auth/errores/404/error-404.scss";
@import "main/Quantion/auth/errores/500/error-500.scss";
@import "main/Quantion/root/gservicios/paquetes/paquetes.scss";
@import "main/Quantion/root/gservicios/servicios/servicios.scss";
@import "quick-panel/quick-panel.scss";
@import "toolbar/toolbar.scss";
@import "main/Quantion/estadisticas/estadisticas.scss";
@import "main/Quantion/administradorCajeros/administradorCajeros.scss";
@import "main/Quantion/lotes/lotes.scss";
@import "main/Quantion/precios/precios.scss";
@import "main/Quantion/principal/principal.scss";
@import "main/Quantion/usuarios/usuarios.scss";
@import "main/Quantion/wifiAdm/listado.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "main/Quantion/archivos/fileExplorer/fileExplorer.scss";
@import "main/Quantion/archivos/nombreRandom/nombreRandom.scss";
@import "main/Quantion/archivos/servicios/servicios.scss";
@import "main/Quantion/auth/login/login.scss";
@import "main/Quantion/avisos/enviar/enviar.scss";
@import "main/Quantion/avisos/enviarpubli/enviarpubli.scss";
@import "main/Quantion/avisos/listado/listado.scss";
@import "main/Quantion/archivos/documentExplorer/documentExplorer.scss";
@import "main/Quantion/estadisticas/banners/banners.scss";
@import "main/Quantion/estadisticas/instantanea/instantanea.scss";
@import "main/Quantion/estadisticas/tv/tv.scss";
@import "main/Quantion/estadisticas/urls/urls.scss";
@import "main/Quantion/habitaciones/aps/aps.scss";
@import "main/Quantion/habitaciones/listado/listado.scss";
@import "main/Quantion/habitaciones/mantenimiento/mantenimiento.scss";
@import "main/Quantion/habitaciones/modelos/modelos.scss";
@import "main/Quantion/habitaciones/unprovisioned/unprovisioned.scss";
@import "main/Quantion/habitaciones/ver_mas/ver_mas.scss";
@import "main/Quantion/habitaciones/voip/voip.scss";
@import "main/Quantion/habitaciones/wallets/wallets.scss";
@import "main/Quantion/iptv/importexport/importexport.scss";
@import "main/Quantion/iptv/listaIptv/listaIptv.scss";
@import "main/Quantion/gestionCompras/consumo/contabilidad.scss";
@import "main/Quantion/gestionCompras/consumo_sin_precios/contabilidad.scss";
@import "main/Quantion/gestionCompras/operaciones/gestionCompras.scss";
@import "main/Quantion/gestionCompras/recaudacion/cajeros.scss";
@import "main/Quantion/gestionCompras/recaudacion_simple/recaudacion_simple.scss";
@import "main/Quantion/gestionCompras/recaudacion_v/recaudacion_v.scss";
@import "main/Quantion/gestionCompras/recaudacion_completa/recaudacion_completa.scss";
@import "main/Quantion/root/controls/controls.scss";
@import "main/Quantion/root/cversiones/cversiones.scss";
@import "main/Quantion/root/dhcpEquipos/dhcpEquipos.scss";
@import "main/Quantion/root/gCajeros/gCajeros.scss";
@import "main/Quantion/root/gruposStb/gruposStb.scss";
@import "main/Quantion/root/idiomas/idiomas.scss";
@import "main/Quantion/root/encuestas/encuestas.scss";
@import "main/Quantion/root/instalaciones/instalaciones.scss";
@import "main/Quantion/root/properties/properties.scss";
@import "main/Quantion/root/tarjetas/tarjetas.scss";
@import "main/Quantion/root/temas/temas.scss";
@import "main/Quantion/root/traduccion/traduccion.scss";
@import "main/Quantion/tasks/equipos/tasks.scss";
@import "main/Quantion/tasks/procesos/procesos.scss";
@import "main/Quantion/tasks/servidor/tasks.scss";
// endinjector