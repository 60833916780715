#estadisticas{
    input {
        background-color: #f7f7f7;
    }

    md-input-container{
        margin: 0;

        h4{
            margin-right: 10px;
        }
    }

    md-radio-group{
        input{
            margin-left: 15px;
        }
    }

    .errorStats{
        text-align: center;
    }

    .stats_shadow{
        cursor: pointer;
    }

    .stats_shadow:hover{
        background: lightsteelblue;
    }

    ol{
        margin: 0;

        span,li{
            font-size: 15px;
        }
    }

    .ms-widget{
        md-content{
            max-height: 50vh;
            overflow: overlay
        }
    }

    .stat-box{
        background: lightgray;
        padding: 5px;
        margin: 6px;
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
        border-radius: 5px;

        md-icon {
            margin-right: 6px;
        }

        img {
            max-height: 24px;
            max-width: 24px;
            margin: auto 6px auto 0;
            object-fit: contain;
        }
    }

    #timeline-container{
        &:not(.complete) .advanced{
            display: none;
        }
    }

    //Estilos para cada tipo de estadística
    .OFF{
        background: tomato;
    }

    .TV_OPENING,.TV{
        background: lawngreen;
    }

    .HOME{
        background: lightblue;
    }

    md-datepicker {
        background: transparent;
        padding-right: 0;
        margin-right: 0;
    }
}