#gCajeros{    
    .md-3-line{
        padding-left: 20px;
        margin:10px 0;
        border: 1px solid lightgray;

        align-items: center;
        min-height: auto;
        padding: 1em;
    }

    .md-3-line:hover{
        background-color: lightgray;
    }

    .features{
        > md-icon{            
            font-size:40px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            opacity: 0.4;
        }

        > md-icon:hover{
            opacity: 1;
        }
    }

    .phone{
        color: lightslategrey;
    }
}

#arqueos{
    .odd{
        background-color: lightgrey;
    }

    .arqueo{
        div:first-child{
            padding: 0 0.5em;
        }
    }
}

#mandos{
    .odd{
        background-color: lightgrey;
    }

    .mando{
        span{
            min-width: 100px;
            margin-right: 20px;
        }

        span:nth-child(3){
            min-width: 200px;
        }
    }
}

#cupones{
    .odd{
        background-color: lightgrey;
    }

    .mando{
        span{
            min-width: 100px;
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #gCajeros {
        md-dialog-content{
            padding: 8px;
        }

        md-list-item {
            .features {
                margin: 10px 0 5px 0;
            }
        }
    }
}

@media screen and (min-width: $layout-breakpoint-sm) {
    #gCajeros {
        .container{
            md-input-container {
                margin: 2px 0;
            }
        }
    }
}