#puntos-de-acceso {

    table{
        min-width:100%;
        border-collapse: collapse;
        margin-top: 1em;
        margin-left: 4px;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }

        .column-title {
            cursor: pointer;
        }
    }

    .tag{
        margin: 4px 8px;
        padding: 2px 4px;
    }

    .verde{
        background-color: rgba(108, 179, 0, 0.7);
    }

    .amarillo{
        background-color: rgba(255, 255, 0, 0.7);
    }

    .gris{
        background-color: lightgrey;
    }

    .activo{
        border-left: 8px solid green;
    }

    .inactivo{
        border-left: 8px solid red;
    }

    .desconocido{
        border-left: 8px solid gray;
    }

    md-tab-content{
        padding: 12px;

        md-select{
            margin: auto 20px;
            min-width: 150px;
        }
    }

    md-dialog-content{        
        md-checkbox{
            margin-top: 16px;
        }
    }

    .pagination-button{
        min-width: 36px;
        font-size: 30px;
        padding: 0;
    }

    .buscador{
        md-input-container{
            margin: 0;

            .md-errors-spacer{
                display: none;
            }
        }

        .pagination-button{
            min-width: 36px;
            font-size: 30px;
            padding: 0;
        }

        >div:first-child{
            border-radius:3em;

            input{
                border-radius: 10px 3em 3em 10px;
                background-color: #f7f7f7; 
            }

            md-icon{
                color: white;
                margin-left: 5px;
            }
        }
    }
}

#import-aps{

    table{
        min-width:100%;
        border-collapse: collapse;
        margin-top: 1em;

        th,td{
            padding: 0 1em;
            text-align: initial;
        }

        td{
            border-top: 1px solid gray;
        }

        .column-title {
            cursor: pointer;
        }

        .verde{
            background-color: rgba(108, 179, 0, 0.7);
        }

        .amarillo{
            background-color: rgba(255, 255, 0, 0.7);
        }
    }
}

.info-tooltip{
    .md-content{
        white-space: initial;
        height: auto;
    }
}

.big-select{
    md-select-menu{
        max-height: 90vh;

        md-content{
            max-height: none;
        }
    }
}

.autocompletedemoCustomTemplate .autocomplete-custom-template li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: normal; 
}
.autocompletedemoCustomTemplate .autocomplete-custom-template li:last-child {
    border-bottom-width: 0; 
}
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-title,
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-metadata {
    display: block;
    line-height: 2; 
}
.autocompletedemoCustomTemplate .autocomplete-custom-template .item-title md-icon {
    height: 18px;
    width: 18px; 
}