$quickPanelWidth: 50%;
$quickPanelMobileWidth: 80%;

#quick-panel {
    width: $quickPanelWidth;
    min-width: $quickPanelWidth;
    max-width: $quickPanelWidth;
    z-index: 99;

    md-content {
        height: 100%;
    }

    .incidencias{
        
        md-card-title{
            padding: 8px;
        }
        
        .descripcion{
            font-size: 16px;
        }
    }
    
    #incidences-container, #prevents-container {
        h3{
            margin-left: 16px;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #quick-panel {
        width: $quickPanelMobileWidth;
        min-width: $quickPanelMobileWidth;
        max-width: $quickPanelMobileWidth;
    }
}