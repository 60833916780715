#procesos {
    input {
        background-color: #f7f7f7;
    }

    md-badge{
        margin: 0 0 0 8px;
        /*background-color: #4288bc;*/
        color: white;
        padding: 3px 6px;
        border-radius: 4px;
    }

    .type-selector {
        > div {
            padding: 8px;
            cursor: pointer;

            &:hover:not(.md-warn-bg) {
                background: lightgray;
            }
        }
    }

    md-list-item {
        &:hover {
            background: lightgray;
        }

        .md-avatar {
            border-radius: 0;
        }

        .md-list-item-text {
            * {
                white-space: normal;
            }
        }
    }
}

#procesos-template {
    .arg-container {
        padding-top: 14px;

        > *{
            margin: 4px;

            &:not(:first-child){
                margin-left: 8px;
            }

            &:not(:last-child){
                margin-right: 8px;
            }
        }

        &:last-of-type {
            margin-bottom: 8px;
        }
    }

    .groupContainer{
        border:1px solid black;
        margin: 10px;
        min-width: 270px;

        .groupHeader{
            padding: 5px 0 5px 20px;
            background-color: black;
            color: white;
        }

        .groupBody{
            padding: 10px;
            max-height: 15em;
            position: relative;
            overflow: auto;

            md-checkbox, md-checkbox:last-of-type{
                margin:3px;
            }
        }

        .md-icon-button{
            margin: 0;
            padding: 0;
            height: auto;

            .icon{
                color: whitesmoke;
            }
        }
    }
}