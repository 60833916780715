#paquetes{

    .uploadContainer{
        border: 2px solid lightgrey;
        border-radius: 10px;
    }

    .input_oculto{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
    }

    .pest{
        border: 1px solid lightgray;
        padding: 5px;
    }

    md-list {
        .md-subheader {
            margin-bottom: 8px;
        }
    }

    md-list-item{
        position:relative;
        transition: background-color 0.25s;

        h4{
            white-space: normal;
            max-height: 32px;
        }

        h3,h4{
            span{
                color: lightslategray;
                transition: color 0.25s;
            }
        }

        >md-icon{
            position: absolute;
            top: 0;
            left: 0;
            color:limegreen;
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin-right: 20px;
        }

        &.paquete{
            img:nth-child(1) {
                display: block;
            }

            img:nth-child(2) {
                display: none;
            }
        }
    }

    md-list-item:hover{
        background-color: lightgray;

        h3,h4{
            span{
                color: initial;
            }
        }

        &.paquete{
            img:nth-child(1) {
                display: none;
            }

            img:nth-child(2) {
                display: block;
            }
        }
    }

    .md-3-line{
        align-items: center;
        min-height: auto;
        padding: 1em;
    }

    .price-table{
        width: auto;
        border-radius: 25px;
        margin: 8px;
    }

    .price_{
        /* Copy styles from ng-messages */
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        /* Set our own color */
        color: grey; 
    }

    .image-md-menu{
        label{
            color: rgba(0,0,0,0.54);
            font-size: 12px;
        }

        img{
            max-width:80px;
            max-height:80px;
        }
    }

    md-sidenav{
        width: 50%;
        max-width: 50%;
    }
}

#asignarServicio{
    .servicio{
        min-width: 280px;
        transition: background 0.25s;

        md-checkbox{
            margin-bottom: 0;
            margin-left: 1em;
        }

        .icon-star{
            color: darkgoldenrod;
        }
    }

    .servicio:hover{
        background: lightgray;
    }

    section {
        border: 1px solid darkgrey;
        margin-top: 4px;
        box-shadow: 2px 2px 2px 0px lightgrey;
    }

    md-list-item{
        transition: background-color 0.25s;

        h4{
            white-space: normal;
            max-height: 32px;
            margin: 8px 0;
        }
    }


    table{
        border-collapse: collapse;
        min-width: 100%;
    }

    table, th, td {
        border: 1px solid black;
    }

    .md-subheader {
        cursor: pointer;

        &:hover {
            background-color: #e0e0e087;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #asignarServicio, #asignar-servicios{        
        .servicio{
            min-width: 0px !important;
        }

        md-dialog-content{
            padding: 8px 16px;
        }
    }

    #paquetes{
        md-tab-item{
            padding: 12px !important;
        }

        md-dialog-content{
            padding: 8px 16px;
        }
    }
}