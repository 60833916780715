#wallets{

    .check-In{
        color: green;

        md-icon{
            color: green;
            margin: 2px;
        }
    }

    .check-Out{
        color: red;

        md-icon{
            color: red;
            margin: 2px;            
        }
    }

    .icon-sync { 
        font-size: 18px;
        width: 18px;
        height: 18px;
        line-height: 18px;

        &.animate {
            -webkit-animation:spin 2s linear infinite;
            -moz-animation:spin 2s linear infinite;
            animation:spin 2s linear infinite;
        }
    }

    .buscador{
        md-input-container{
            margin: 0;

            .md-errors-spacer{
                display: none;
            }
        }

        .pagination-button{
            min-width: 36px;
            font-size: 30px;
            padding: 0;
        }

        >div:first-child{
            border-radius:3em;

            input{
                border-radius: 10px 3em 3em 10px;
                background-color: #f7f7f7; 
            }

            md-icon{
                color: white;
                margin-left: 5px;
            }
        }

        span{
            margin-left: 3px;
            margin-right: 8px;
        }
    }

    table{
        min-width:100%;
        border-collapse: collapse;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }

        .column-title {
            cursor: pointer;

            &:after {
                position: relative;
                font-family: 'icomoon';
                font-weight: normal;
                margin-left: 10px;
                top: 2px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .ord-no {

            &:after {
                content: '\eaa8';
            }
        }

        .ord-asc{

            &:after {
                content: '\e718';
            }
        }

        .ord-desc{

            &:after {
                content: '\e715';
            }
        }
    }

    md-select{
        transition:background-color 0.5s;
        background-color: #f7f7f7; 
    }
}

#vincularStb{
    md-radio-button{
        margin-top: 12px;
        margin-bottom: 12px;
    }
}

#lista-check-in-out {
    table{
        min-width:100%;
        border-collapse: collapse;

        th, td {
            border: 1px solid gray;
        }
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }