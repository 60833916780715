#controls{
    .control{
        margin-bottom: 1em;
        border-radius: 8px;

        h2{
            margin-left: 12px;
        }
    }
    
    .margin-0 {
        margin: 0 !important;
    }
}