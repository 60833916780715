#voip{
    
    md-tab-content {
        border: 1px solid lightgray;
        border-top: none;
    }

    .buscador{
        md-input-container{
            margin: 0;

            .md-errors-spacer{
                display: none;
            }
        }

        .pagination-button{
            min-width: 36px;
            font-size: 30px;
            padding: 0;
        }

        >div:first-child{
            border-radius:3em;

            input{
                border-radius: 10px 3em 3em 10px;
                background-color: #f7f7f7; 
            }

            md-icon{
                color: white;
                margin-left: 5px;
            }
        }

        span{
            margin-left: 3px;
            margin-right: 8px;
        }
    }

    table{
        min-width:100%;
        border-collapse: collapse;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }

        .column-title {
            cursor: pointer;

            &:after {
                position: relative;
                font-family: 'icomoon';
                font-weight: normal;
                margin-left: 10px;
                top: 2px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .ord-no {

            &:after {
                content: '\eaa8';
            }
        }

        .ord-asc{

            &:after {
                content: '\e718';
            }
        }

        .ord-desc{

            &:after {
                content: '\e715';
            }
        }
    }

    md-select{
        transition:background-color 0.5s;
        background-color: #f7f7f7; 
    }

    .fila:hover{
        background-color: #e4e4e4;
    }
}

#tarifas{
    .tarifa{
        border: 1px solid;
        border-radius: 8px;
        padding: 8px;
        margin: 8px;
        text-align: center;

        h1{
            color: red;
            font-weight: bold;
        }
    }

    md-switch{
        margin: 0 4px 0 12px;
    }

    md-sidenav{
        min-width: 50%;
    }

    md-tabs{
        border: 1px solid lightgray;
    }
}

#knocks{
    table{
        border-collapse: collapse;
        width: 100%;
    }

    tr:nth-child(even){
        background-color: #f2f2f2
    }

    th, td {
        text-align: left;
        padding: 4px;
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #knocks{
        max-height: -webkit-fill-available;
        max-width: -webkit-fill-available;
    }
}