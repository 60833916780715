#encuestas{
    .md-2-line{
        border: 1px solid lightgray;
        padding-left: 10px;
        transition:background-color 0.25s;

        h3,h4{
            span{
                color: lightslategray;
                transition:color 0.25s;
            }
        }
        
        .md-avatar {
            border-radius: 0;
        }
    }

    .md-2-line:hover{
        background-color: rgba(146, 25, 142, 0.35);

        h3,h4{
            span{
                color: initial;
            }
        }
    }

    .lang-select{
        *:first-child{
            transform: none;
            -webkit-transform: none;
        }
    }
}

#editarEncuesta{
    .image-container {
        padding: 8px;
        cursor: pointer;
        margin-right: 12px;

        &:hover {
            background: lightgray;
        }
    }

    img{
        max-height: 100px;
        max-width: 100px;
        align-self: center;
    }

    .lang-select{
        *:first-child{
            transform: none;
            -webkit-transform: none;
        }
    }
}

#preguntasEncuesta{

    md-dialog-content{
        padding: 0;

        .question-list {        
            .element {
                padding: 8px;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .respuestas {

            .bold{
                font-weight: bold;
            }

            h4:not(.bold){
                margin: 0;
            }
        }

        img{
            max-height: 100px;
            max-width: 100px;
        }

        .question-list, form {
            overflow: auto;
        }

        form {
            padding: 8px;
        }
    }

    .hint{
        position: initial;
    }
}

#respuestasEncuesta{
    li{
        color: darkslategray;

        span{
            color: initial;
            font-size: 15px;
        }
    }

    li:nth-child(odd){
        background-color: palegoldenrod;
    }
    
    .bar-container {
        width: 100%;
        min-height: 30px;
        
        .bar {
            height: inherit;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #editarEncuesta{

        md-dialog-content{
            padding: 8px 16px;
        }
    }

    #preguntasEncuesta{

        md-dialog-content .question-list .element {
            padding: 4px 8px;
            font-size: 12px;
        }

        md-content{
            max-height: 100vh !important;
            padding-right: 0 !important;
        }

        .abs-button{
            top: -2px !important;
            right: 0 !important;
        }
    }
}