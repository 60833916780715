/*#enviarAviso {

    .tipo-aviso{
        img {
            max-width: 320px;
        }
    }

    input.ng-invalid, textarea.ng-invalid{
        background-color: pink;
    }

    input.ng-valid, textarea.ng-valid{
        background-color: lightgreen;
    }

    input.ng-pristine, textarea.ng-pristine, md-select, md-datepicker-input-container{
        background-color: #f7f7f7; 
    }

    input, textarea, md-select{
        transition:background-color 0.5s;
    }

    .pantallaCarga{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(150,150,150,0.7);

        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        h1, h3{
            color: #000;
        }
    }

    .selector{
        background-color: black;
        height: 80px;
        max-width: 150px;
    }

    md-input-container img{
        margin-right: 20px;
        background-color: black;
        max-height: 100px;
        max-width: 100px;
        padding: 10px;
        border-radius: 1em;
        min-height: 100px;
        min-width: 100px;
    }

    form{
        margin:0 2em;

        md-checkbox{
            margin:5px 0;
        }
    }

    .temporizacion{
        input, md-datepicker, md-select{
            background-color: rgba(255, 255, 255, 0.3)
        }

        > div {
            > div {
                background-color: rgba(0, 0, 0, 0.3);;
                border-radius: 0.5em;
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    
    #enviarAviso {
        md-input-container img{
            margin-right: 0;
        }
        
        form{
            margin: 0;
        }
    }
}

#elegirImagenNotif{
    
    md-dialog-content{
        padding: 12px;
    }

    img{
        cursor: pointer;
        border: 4px solid transparent;
        margin: 6px;
        background-color: black;
        max-height: 80px;
        max-width: 80px;
        padding: 4px;
        border-radius: 1em;
        min-height: 80px;
        min-width: 80px;
    }

    img:hover{
        border:4px solid red;
    }
}*/