#cVersiones{
    .uploadContainer{
        border: 2px solid lightgrey;
        border-radius: 10px;
    }

    .fileData{
        border: 2px solid lightgrey;
        border-radius: 10px;
        min-width: 320px;

        img{
            max-height: 90px;
            max-width: 90px;
        }
    }

    input.ng-invalid{
        background-color: pink;
    }

    input.ng-valid{
        background-color: lightgreen;
    }

    input.ng-pristine, textarea{
        background-color: #f7f7f7; 
    }

    input{
        transition:background-color 0.5s;
    }

    h4{
        text-align: right;

        background-color: #fefdbe;

        background: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(254,253,190,1));
        background: -o-linear-gradient(right, rgba(0,0,0,0), rgba(254,253,190,1));
        background: -moz-linear-gradient(right, rgba(0,0,0,0), rgba(254,253,190,1));
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(254,253,190,1));
    }

    .pantallaCarga{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: rgba(150,150,150,0.7);

        .content{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        h1, h3{
            color: #000;
        }
    }

    .error{
        /* Position the hint */
        left: 2px;
        right: auto;
        bottom: 7px;
        /* Copy styles from ng-messages */
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        /* Set our own color */
        color: red; 
    }

    .hint{
        position: initial;
        /* Position the hint */
        left: 2px;
        right: auto;
        bottom: 7px;
        /* Copy styles from ng-messages */
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        /* Set our own color */
        color: gray; 
    }

    .vrsn{
        padding-left: 8px;
        min-width: 200px;
        border-radius: 6px;
        margin: 1px;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #cVersiones{
        .fileData{
            min-width: 0;
        }

        md-input-container{
            margin: 0;
        }

        .md-inline-form md-radio-group md-radio-button {
            height: auto;
        }
    }
}