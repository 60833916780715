#documentExplorer {
    md-list{
        md-list-item{
            padding-left:16px;

            .md-avatar{
                border-radius: 0;
                height: auto;
                max-height: 40px;
            }

            .folder p{
                font-weight: bolder;
            }
        }
    }

    .bar{
        md-icon{
            cursor: pointer;
        }
    }

    .archivos{
        padding-top: 0;

        md-list-item:hover{
            background-color: rgba(158,158,158,0.2);
        }
    }

    .grid{
        md-list-item{
            margin: 8px;
            padding-left: 0;
            width: 100px;

            .md-list-item-inner{
                max-width: 100%;
            }

            img{
                margin: auto;
                height: 40px;
                object-fit: contain;
            }

            p{
                text-overflow: ellipsis;
                max-width: 100%;
                overflow: hidden;
            }

            md-menu{
                position: absolute;
                right: 0;
                top: 0;

                button{
                    padding: 0;
                    margin: 0;
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}
