#error401 {
    height:100%;

    img{
        max-height: 320px;
        max-width: 320px;
    }
    
    .error-code {
        font-size: 112px;
        text-align: center;
        line-height: 1;
        margin-bottom: 16px;
        font-weight: 500;
    }

    .message {
        font-size: 24px;
        text-align: center;
        color: rgba(0, 0, 0, 0.54);
    }
}