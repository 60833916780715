#vertical-navigation {
    width: $navigationWidth;
    min-width: $navigationWidth;
    max-width: $navigationWidth;
    overflow-x: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
    box-shadow: $whiteframe-shadow-6dp;

    .navigation-header {
        height: 64px;
        min-height: 64px;
        background-color: rgba(255, 255, 255, 0.05);
        padding: 0 10px;

        .logo {
            .part1,.part2{
                position:absolute;
                max-width: 175px;
                max-height: 45px;
            }
            .part2{
                /*position:absolute;*/

                transition: opacity 0.3s;
            }
        }

        .versionText{
            position: absolute;
            bottom: 3px;
            /*right: 3px;*/
            left: 190px;
            z-index: 9999;
            font-size: 12px;
        }

        .fold-toggle {
            transition: transform 0.3s ease-in-out 0.1s;
            transform: rotate(0deg);
            margin: 0;
            padding: 8px;
            width: 34px !important;
            height: 34px !important;
            cursor: pointer;
        }
    }

    &:not(.md-locked-open) {
        box-shadow: $whiteframe-shadow-8dp;
    }

    .widgets{
        /*position: fixed;*/
        width: $navigationWidth;
        /*bottom: 0;*/
        /*left: 0;*/
        /*right: 0;*/
        border-top: 2px solid rgba(255, 255, 255, 0.7);

        /*transform: translateY(calc(100% - 97px));*/
        /*transition: all 0.5s;*/
    }

    .widgets:hover{
        /*transform: translateY(0);*/
    }
}

// Responsive
@media only screen and (max-width: $layout-breakpoint-sm) {
    .scrollable{
        height: calc(100vh - 64px);
        margin-bottom: 0;
    }
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {

    .ms-navigation-folded {

        #content-container {
            margin-left: $navigationFoldedWidth;
        }

        #vertical-navigation {
            position: absolute;

            .navigation-header {

                .fold-toggle {
                    transform: rotate(180deg);
                    opacity: 0;
                }
            }
        }

        &.ms-navigation-folded-open {

            #vertical-navigation {

                .navigation-header {

                    .fold-toggle {
                        transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
                        opacity: 1;
                    }
                }
            }
        }

        &:not(.ms-navigation-folded-open) {

            #vertical-navigation {
                position: absolute;
                width: $navigationFoldedWidth;
                min-width: $navigationFoldedWidth;
                max-width: $navigationFoldedWidth;

                .navigation-header {
                    padding: 0 10px;

                    .logo {
                        .part1{
                            max-height:45px;
                        }
                        .part2{
                            opacity: 0;
                        }
                    }

                    .versionText{
                        display: none;
                    }

                    .instText{
                        font-size: 0;
                    }
                }

                .widgets{
                    bottom: -419px;
                }
            }
        }
    }

}