#listaIptv{
    md-badge{
        margin: 0 0 0 8px;
        /*background-color: #259b24;*/
        color: white;
        padding: 3px 6px;
        border-radius: 4px;
    }

    .canalVisible:hover{
        background-color: #e4e4e4;
    }

    .canalNoVisible{
        background-color: #fd6464;
    }

    .canalNoVisible:hover{
        background-color: #8d2c2c;
    }

    .normal,.high, .critical, .adv, .alarmas, .noAlarmas{
        border-radius: 15px;
        padding: 5px;
        margin: 5px;

        md-icon{
            margin-right: 15px;
        }
    }

    .adv{        
        background-color: #fefdbe;

        background: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(254,253,190,1));
        background: -o-linear-gradient(right, rgba(0,0,0,0), rgba(254,253,190,1));
        background: -moz-linear-gradient(right, rgba(0,0,0,0), rgba(254,253,190,1));
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(254,253,190,1));
    }

    .normal{
        background-color: #87ff8b;

        background: -webkit-linear-gradient(left, rgba(135,255,139,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(135,255,139,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(135,255,139,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(135,255,139,1), rgba(0,0,0,0));
    }

    .high{
        background-color: #fdef4c;

        background: -webkit-linear-gradient(left, rgba(253,239,76,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(253,239,76,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(253,239,76,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(253,239,76,1), rgba(0,0,0,0));
    }

    .critical{
        background-color: #fd6464;

        background: -webkit-linear-gradient(left, rgba(253,100,100,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(253,100,100,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(253,100,100,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(253,100,100,1), rgba(0,0,0,0));
    }

    .alarmas{
        background-color: #ffc621;

        background: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(255,198,33,1));
        background: -o-linear-gradient(right, rgba(0,0,0,0), rgba(255,198,33,1));
        background: -moz-linear-gradient(right, rgba(0,0,0,0), rgba(255,198,33,1));
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(255,198,33,1));

        max-height: 32px;
        overflow-y: hidden;
        transition: max-height 0.5s;
    }

    .alarmas:hover{
        max-height: 180px;
    }

    .noAlarmas{
        background-color: #87ff8b;

        background: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(135,255,139,1));
        background: -o-linear-gradient(right, rgba(0,0,0,0), rgba(135,255,139,1));
        background: -moz-linear-gradient(right, rgba(0,0,0,0), rgba(135,255,139,1));
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(135,255,139,1));
    }

    table{
        min-width:100%;
        border-collapse: collapse;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }
    }
}

#editarCanal{
    .logo-container{
        margin-right: 12px;
        width: 150px;
        height: 150px;
        background-color: lightgray;
        cursor: pointer;
        padding: 10px;
        border-radius: 1em;

        img{
            max-height: 100%;
            max-width: 100%;
        }
    }

    md-switch {
        margin: 0;

        .md-container{
            margin: 0 8px;
        }
    }
}

#elegirImagenCanal{

    .element{
        cursor: pointer;
        margin: 4px 8px;
        padding: 4px;
        width: 80px;

        .img-container{
            height: 60px;
            width: 60px;

            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
        
        span{
            word-wrap: break-word;
        }

        /*transition:border 0.5s;*/

        &:hover{
            background-color: lightgray;
        }
    }
}