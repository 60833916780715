#idiomas{

    md-list-item{
        margin: 10px 0;
        border: 1px solid lightgray;
        
        img{
            margin: 0 10px;
            max-height: 32px;
            max-width: 48px;
        }
    }

    md-list-item:hover{
        background-color: rgba(255,0,0,0.35);

        background: -webkit-linear-gradient(left, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(255,0,0,0.35), rgba(0,0,0,0));
    }
}