#wifiAdm {

    md-select{
        margin: auto 20px;
        min-width: 150px;
    }

    table{
        min-width:100%;
        border-collapse: collapse;
        margin-top: 1em;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;

            .green-check{
                font-size: 36px;
                color: green;
            }

            .red-cross{
                font-size: 36px;
                color: red;                
            }
        }

        .column-title {
            cursor: pointer;   
            text-align: initial;
        }
    }
}
