#estadisticas-urls{
    input {
        background-color: #f7f7f7;
    }

    md-input-container{
        margin: 0;

        h4{
            margin-right: 10px;
        }
    }

    md-radio-group{
        input{
            margin-left: 15px;
        }
    }

    .errorStats{
        text-align: center;
    }

    md-autocomplete{
        /*min-width: 300px;*/
    }

    md-datepicker {
        background: transparent;
        padding-right: 0;
        margin-right: 0;
    }
}