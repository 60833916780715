#instalaciones{
    .md-3-line{
        margin:10px 0;
        border: 1px solid lightgray;
        padding-left: 10px;
        transition:background-color 0.25s;

        h3,h4{
            white-space: normal;

            span{
                color: lightslategray;
                transition:color 0.25s;
            }
        }

        .md-avatar{
            border-radius: initial;
            height: auto;
            margin: 8px 16px 8px 0;
            align-self: center;
        }
    }

    .md-3-line:hover{
        background-color: rgba(150, 191, 25, 0.35);

        /*        background: -webkit-linear-gradient(left, rgba(150, 191, 25, 0.35), transparent);
                background: -o-linear-gradient(right, rgba(150, 191, 25, 0.35), transparent);
                background: -moz-linear-gradient(right, rgba(150, 191, 25, 0.35), transparent);
                background: linear-gradient(to right, rgba(150, 191, 25, 0.35), transparent);*/

        h3,h4{
            span{
                color: initial;
            }
        }
    }

    .md-icon-button{
        align-self: center;
    }

    .icon-star{
        color: darkgoldenrod;
    }

    .lang-select{
        *:first-child{
            transform: none;
            -webkit-transform: none;
        }
    }
    
    textarea {
        overflow: visible;
    }
}

#verServiciosInstalacion{
    md-list{
        border: 1px solid lightgray;
        margin: 0 5px;
        position:relative;

        .md-menu{
            position:absolute;
            top: 0;
            right: 0;
        }

        md-list-item{
            padding-left: 1em;
            min-height: 0;
            background-color: lightgrey;
            border-radius: 4em;
            border: 2px solid transparent;
            margin: 3px;

            .md-button{
                align-self: center;
                padding: 0;
                width: initial;
                height: initial;
            }
        }

        md-list-item:hover{
            border: 2px solid black;
        }
    }
}

#ordenHome{
    .orden{
        .elem{
            padding: 8px;
            margin: 1em;
            border: 1px solid lightgray;
            position: relative;
            background-color: white;
            cursor: move;

            .delete-button{
                position: absolute;
                top: -20px;
                right: -20px;
            }

            .left-button{
                position: absolute;
                bottom: -20px;
                left: -20px;
            }

            .right-button{
                position: absolute;
                bottom: -20px;
                right: -20px;
            }

            .change-state-button{
                position: absolute;
                top: -20px;
                left: -20px;
            }

            h4{
                margin: 0;
            }
        }
    }

    .lsta-highlight{
        background-color: yellow;
        min-height: 70px;
        min-width: 70px;
        border-radius: 1em;
        margin:13px;
    }

    .orden-sidebar{
        border: 1px solid gray;
        padding: 3px 20px 3px 3px;
        margin-right: 1em;

        img{
            width: 50px;
        }

        .elem{
            border-radius: 1em;
        }

        h3{
            margin-top: 0;
        }
    }

    .orden-home{
        border: 1px solid gray;
        padding: 3px;

        img{
            width: 50px;
        }

        .elem{
            border-radius: 1em;
        }

        h3{
            margin-top: 0;
        }
    }

    .orden-extra{
        border: 1px solid gray;
        padding: 3px;
        margin-top: 1em;

        img{
            width: 50px;
        }

        .elem{
            border-radius: 1em;
        }

        h3{
            margin-top: 0;
        }
    }

    .elem-base{
        background: lightgreen !important;
    }

    .elem-purchasable{
        background: lightcoral !important;
    }

    md-badge{
        margin: 8px;
        padding: 3px 6px;
        border-radius: 4px;
        border: 1px solid;

        &:first-child{
            background-color: white;
        }

        &:nth-child(2){
            background-color: lightgreen;
        }

        &:nth-child(3){
            background-color: lightcoral;
        }
    }

    .botones-estado{
        .md-button{
            &:first-child{
                background-color: white;
            }

            &:nth-child(2){
                background-color: lightgreen;
            }

            &:nth-child(3){
                background-color: lightcoral;
            }
        }
    }

    .leds{
        div{
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid darkgray;
            margin: 2px 4px;
            cursor: pointer;

            &:first-child{
                background-color: white;
            }

            &:nth-child(2){
                background-color: lightgreen;
            }

            &:nth-child(3){
                background-color: lightcoral;
            }
        }
    }
}

#ordenPaquetes{
    .orden {
        .elem {
            margin: 6px;
            border: 1px solid lightgray;
            background-color: white;
            cursor: move;

            img {
                max-width: 50px;
                max-height: 50px;
            }

            .delete-button{
                margin-left: 4px;

                .md-button{
                    margin: 0;
                }
            }

            .left-right-buttons {
                margin-right: 8px; 

                .md-button{
                    margin: 0;
                    border-radius: 0;

                    &[disabled] {
                        background-color: #696969;

                        md-icon{
                            color: #ffffff42 !important;
                        }
                    }

                }
            }

            h3{
                margin: 0 4px;
            }
        }
    }

    .lsta-highlight {
        background-color: gray;
        min-height: 40px;
        min-width: 80px;
        border-radius: 1em;
        margin: 6px;
    }
}

#import-instalaciones{

    .instalacion{
        min-width: 280px;
        transition: background 0.25s;

        md-checkbox{
            margin-bottom: 0;
            margin-left: 1em;
        }
    }

    .instalacion:hover{
        background: lightgray;
    }

    md-list-item{
        transition: background-color 0.25s;

        h4{
            white-space: normal;
            max-height: 32px;
            margin: 8px 0;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #import-instalaciones{
        .instalacion{
            min-width: 0;
        }

        md-dialog-content{
            padding: 8px 16px;
        }
    }
}