.ps-container {

    &:hover {

        .ps-in-scrolling {
            pointer-events: auto;
        }
    }

    .ps-in-scrolling {
        pointer-events: auto;
    }

    > .ps-scrollbar-x-rail {
        background: none !important;
        margin: 4px 0;
        height: 4px;
        z-index: 49;

        > .ps-scrollbar-x {
            height: 6px;
            transition: height 0.25s;
        }
        
        > .ps-scrollbar-x:hover {
            height: 1em;
        }
    }

    > .ps-scrollbar-y-rail {
        background: none !important;
        margin: 4px 0;
        width: 4px;
        z-index: 49;

        > .ps-scrollbar-y {
            width: 6px;
            transition: width 0.25s;
        }
        
        > .ps-scrollbar-y:hover {
            width: 1em;
        }
    }
}