#ver_masHabitacion {

    input.ng-invalid{
        background-color: pink;
    }

    input.ng-valid{
        background-color: lightgreen;
    }

    input.ng-pristine{
        background-color: lightgray; 
    }

    .text-gray{
        margin: 5px 0;

        span:first-child{
            font-size: 80%;
            color: lightslategray;
        }
    }

    md-list{
        .md-list-item-text{margin:7px;}
        md-list-item{min-height: 0px;}
        md-list-item:hover{background-color: lightgray;}

        .Balance{
            background-color: orange;
        }

        .Cash{
            background-color: lime;
        }

        .Cortesy{
            background-color: greenyellow;
        }

        .Card{
            background-color: #4ea7e6;
        }

        .None{
            background-color: gray;
        }

        .PendingGive{
            background-color: orchid;
        }

        .PendingReceive{
            background-color: yellow;
        }

        .idAccountant{
            min-height: 88px;
            padding-right: 5px;
            line-height: 88px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }

        .ingreso{
            color:green;
        }
        .gasto{
            color:red;
        }
        .no{
            display: none;
        }
    }

    .strong{
        font-weight: bold;
    }
    
    .underline {
        text-decoration: underline;
    }
}

.bloqueFila{
    border: 2px solid #b4b4b4;
    padding-left: 20px;
    padding-right: 20px;
    /*margin: 10px 20px;*/
    margin-bottom: 15px;
    border-radius: 15px;
}