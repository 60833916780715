#recaudacion_completa{
    md-datepicker{
        margin-right: 0;
        padding-right: 0;
        background-color: transparent;
    }

    table {
        width: 100%;
        margin-bottom: 1em;
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    th, td {
        padding: 5px;
        text-align: center;
    }
    th {
        background-color: #8a8a8a;
    }
    td {
        background-color: #eee;
    }
}