#servicios{
    md-badge{
        margin: 0 0 0 8px;
        /*background-color: #259b24;*/
        color: white;
        padding: 3px 6px;
        border-radius: 4px;
    }

    .canal-list-item{
        min-width: 375px;
        transition: background 0.25s;
    }

    .canal-list-item:hover{
        background: lightgray;
    }

    .pest{
        border: 1px solid lightgray;
        padding: 5px;
    }

    md-list-item{
        transition: background-color 0.25s;
        cursor: auto;

        img[ng-click] {
            cursor: pointer;
            transition: opacity 0.25s;

            &:hover {
                opacity: 0.6;
            }
        }

        h3, h4{
            white-space: normal;
            /*max-height: 32px;*/
            span{
                color: lightslategray;
                transition: color 0.25s;
            }
        }

        .iconSelected{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            color: royalblue;
        }

        >md-icon{
            position: absolute;
            top: 0;
            left: 0;
            color:limegreen;
        }

        .flag {
            position: absolute;
            top: 0;
            left: 50px;
            width: 24px;
            height: 24px;
        }
    }

    md-list > md-list-item:hover{
        background-color: lightgray;

        h3,h4{
            span{
                color: initial;
            }
        }
    }

    .md-3-line{
        align-items: center;
        min-height: auto;
        padding: 1em;
    }

    .asig-list{
        height:100%;

        .item{
            max-height: 30px;
        }
    }

    .listaCanales{
        h4{
            margin: 5px;
        }

        margin: 5px;
        border: 1px solid gray;
        border-radius: 5px;
        cursor: pointer;

        background: white;

        min-width: 30%;
        max-width: 30%;
    }

    .listaCanales:hover{
        background: lightgray;
    }

    .lsta{
        min-width: 300px;
        max-width: 500px;
    }

    .lsta-highlight{
        background: yellow;
        height: 29px;
        min-width:300px;
    }

    .tab{
        min-height: 70vh;
    }

    .mini{
        width: 20px;
        height:20px;
        min-height: 20px;
    }

    .busqueda{
        position: fixed;
        z-index: 22;
        margin: 0;
        max-height: 80%;

        transition: all 0.2s;

        md-checkbox{
            margin: 0;
        }
    }

    .left{
        left:-195px;
    }

    .left:hover{
        left:0;
    }

    .right{
        right:-195px;
    }

    .right:hover{
        right:0;
    }

    .md-tab{
        overflow: initial;
    }

    md-divider{
        border-top-style: dashed;
    }

    .hint{
        position: initial;

        >div {
            cursor: pointer;
            margin-left: 3px;

            &:hover {
                color: indigo;
            }
        }
    }

    .image-md-menu{
        label{
            color: rgba(0,0,0,0.54);
            font-size: 12px;
        }

        img{
            max-width:80px;
            max-height:80px;
        }
    }

    .orden-grupo-highlight{
        background: yellow;
        height: 29px;
        min-width: 150px;
    }

    .listaOrdenGrupo{
        h4{
            margin: 5px;
        }

        margin: 5px;
        border: 1px solid gray;
        border-radius: 5px;
        cursor: pointer;

        background: white;

        min-width: 150px;
        max-width: 300px;
    }

    .listaOrdenGrupo:hover{
        background: lightgray;
    }

    .servicio-second-div{
        margin-left: 2em;
        max-width: 300px;
    }

    .mainTab md-tabs-wrapper{
        display: none;
    }

    .md-subheader {
        text-transform: uppercase;
    }

    md-list-item img {
        width: 50px;
        height: 50px;
        object-fit: contain;
        margin-right: 20px;
    }
}

#samples, #calendar{
    .edicion{
        padding: 10px;

        md-input-container{
            .md-errors-spacer{
                display:none;
            }
        }

        img{
            max-height: 18px;
        }

        .pest{
            border: 1px solid lightgray;
            padding: 5px;
        }
    }

    md-list{
        position:relative;

        md-list-item:hover{
            background-color: lightgray;
        }

        .md-2-line{
            .md-button{
                align-self: center;
            }
        }
    }

    .btn-abs{
        position: absolute;
        top: 0;
        right: 0;
    }

    md-sidenav{
        width: 60%;
        max-width: none;
    }
}

#samples{
    .enlace-archivo{
        padding: 4px 12px;
        font-size: 16px;
        margin: 2px;
        border-radius: 6px;
    }

    .md-2-line{
        .md-list-item-img{
            height: 72px;
            width: 72px;
            margin-right: 8px;
            display: flex;

            img{
                max-height: 100%;
                max-width: 100%;
                margin: auto;
            }
        }
    }

    .replicar{
        font-size: 12px;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
}

#asignar-servicios{

    .servicio{
        min-width: 280px;
        transition: background 0.25s;

        md-checkbox{
            margin-bottom: 0;
            margin-left: 1em;
        }

        .icon-star{
            color: darkgoldenrod;
        }
    }

    .servicio:hover{
        background: lightgray;
    }

    md-list-item{
        transition: background-color 0.25s;

        h4{
            white-space: normal;
            max-height: 32px;
            margin: 8px 0;
        }
    }

    md-radio-button{
        margin-right: 32px;
    }

    .md-subheader {
        cursor: pointer;

        &:hover {
            background-color: #e0e0e087;
        }
    }
}

#radio-countries{
    /*    figure{
            cursor: pointer;
        }*/

    md-grid-tile-footer{
        background: rgba(0, 0, 0, 0.7);

        figcaption{
            width: 100%;
            text-align: center;
        }
    }

    .selected{
        md-grid-tile-footer{
            background: rgba(43, 134, 1, 0.9);
        }
    }
}

#modal-actions {

    .pest{
        border: 1px solid lightgray;
        padding: 5px;
    }

    .edicion{
        padding: 10px;

        md-input-container{
            .md-errors-spacer{
                display:none;
            }
        }

        img{
            max-height: 18px;
        }
    }

    md-list{
        position:relative;

        md-list-item:hover{
            background-color: lightgray;
        }

        .md-2-line{
            .md-button{
                align-self: center;
            }
        }
    }

    .btn-abs{
        position: absolute;
        top: 0;
        right: 0;
    }

    md-sidenav{
        width: 60%;
        max-width: none;
    }
}

#imagenes-servicio {
    table {
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid darkgray;
        text-align: left;
        padding: 8px;
        vertical-align: top;
    }

    td:not(:first-of-type) {
        background: #ebebeb;
        transition: background 0.25s;
        position: relative;

        &:hover {
            background: #d6d6d6;            
        }

        .md-button {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    img{
        max-width: 50px;
        max-height: 50px;
    }
}

#export-servicios{
    padding: 0;

    .servicio{
        min-width: 225px;
        margin: 4px 0;
    }

    md-checkbox{
        margin-bottom: 0;
        margin-left: 1em;
    }

    .md-subheader .md-button {
        margin: 0;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #servicios{
        .servicio-second-div{
            margin-left: 0;
            max-width: none;
        }

        md-tab-item{
            padding: 12px !important;
        }

        md-dialog-content{
            padding: 8px 16px;
        }

        .listaCanales{
            min-width: 100%;
            max-width: 100%;
        }
    }

    #samples, #calendar{

        md-sidenav{
            width: 100%;
        }
    }
}