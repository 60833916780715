#instantanea{
    table {
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid darkgray;
        text-align: left;
        padding: 8px;
        vertical-align: top;
    }

    tr:nth-child(even) {
        background-color: #dddddd;
    }

    .column-title {
        cursor: pointer;

        &:after {
            position: relative;
            font-family: 'icomoon';
            font-weight: normal;
            margin-left: 10px;
            top: 2px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .ord-no {

        &:after {
            content: '\eaa8';
        }
    }

    .ord-asc{

        &:after {
            content: '\e718';
        }
    }

    .ord-desc{

        &:after {
            content: '\e715';
        }
    }

    .filter-tr {

        td {
            padding: 2px;

            input {
                width: 100%;                
            }
        }
    }

    .date-container {
        md-datepicker {
            background: transparent;
            padding-right: 0;
            margin-right: 0;
        }
    }

    .groupContainer{
        border:1px solid black;
        margin: 10px;
        min-width: 270px;

        .groupHeader{
            padding: 5px 0 5px 10px;
            background-color: black;
            color: white;
        }

        .groupBody{
            padding: 10px;
            max-height: 15em;
            position: relative;
            overflow: auto;

            md-checkbox, md-checkbox:last-of-type{
                margin:3px;
            }

            .d{
                /*padding-left: 1em;*/
                font-size: 1.3rem;
                font-style: italic;
            }

            .md-avatar {
                max-height: 50px;
                max-width: 50px;
            }
        }

        .md-icon-button{
            margin: 0;
            padding: 0;
            height: auto;

            .icon{
                color: whitesmoke;
            }
        }

        .stb-container{
            margin: 4px 8px;
            padding: 2px;
            border-radius: 2em;
            background: lightgray;
            cursor: pointer;

            .icon-checkbox-blank-circle{
                color: darkgray;
            }

            span{
                margin: 0 8px 0 2px;
            }
        }

        .checked {
            background: green;

            .icon-checkbox-marked-circle{
                color: limegreen;
            }
        }

        .range-ini {
            position: relative;
            background: yellow;

            &:before {
                content: ">";
                position: absolute;
                left: -8px;
            }
        }
    }
}