#usuarios{
    .BASE{
        border: 2px solid red;

        .role{
            background: red;
        }
    }

    .BASE:hover{
        background-color: rgba(255,0,0,0.35);

        background: -webkit-linear-gradient(left, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(255,0,0,0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(255,0,0,0.35), rgba(0,0,0,0));
    }

    .INSTALLER{
        border: 2px solid blue;

        .role{
            background: blue;
        }
    }

    .INSTALLER:hover{
        background-color: rgba(0,0,255,0.35);

        background: -webkit-linear-gradient(left, rgba(0,0,255,0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(0,0,255,0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(0,0,255,0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(0,0,255,0.35), rgba(0,0,0,0));
    }

    .ADMINISTRATOR{
        border: 2px solid green;

        .role{
            background: green;
        }
    }

    .ADMINISTRATOR:hover{
        background-color: rgba(0,255,0,0.35);

        background: -webkit-linear-gradient(left, rgba(0,255,0,0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(0,255,0,0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(0,255,0,0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(0,255,0,0.35), rgba(0,0,0,0));
    }

    .ROOT{
        border: 2px solid #e2d12b;

        .role{
            background: #e2d12b;
        }
    }

    .ROOT:hover{
        background-color: rgba(226, 209, 43, 0.35);

        background: -webkit-linear-gradient(left, rgba(226, 209, 43, 0.35), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(226, 209, 43, 0.35), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(226, 209, 43, 0.35), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(226, 209, 43, 0.35), rgba(0,0,0,0));
    }

    .usuario{
        padding: 18px 10px 1px 10px;
        margin: 2px;

        .role{
            padding: 1px 10px;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom-right-radius: 10px;
        }

        .md-avatar{
            border-radius: initial;
            height: auto;
        }

        h3{
            /*margin-right: 2em;*/
            min-width: 100px;
        }
    }

    .passDiv{
        background: lightsteelblue;
        padding: 10px;
        border-radius: 10px;
    }

    .lang-select{
        *:first-child{
            transform: none;
            -webkit-transform: none;
        }
    }
}

.md-mini-mini{
    line-height: 32px !important;
    width: 32px !important;
    height: 32px !important;
    margin: 4px;

    md-icon{
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

#permisos{
    .routes{
        md-checkbox{
            background: lightgray;
            padding: 2px 6px 2px 0;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-xs) {

    #usuarios{
        .usuario{
            h3{
                min-width: 0;
            }
        }
    }
}