#toolbar {
    height: $toolbarHeight;
    min-height: $toolbarHeight;
    max-height: $toolbarHeight;
    z-index: 55 !important;
    background-image: url('/assets/images/backgrounds/header-bg.png');/////////<----
    background-size: cover;////////////<----

    .toolbarTitle{
        position:relative;

        .sectionName{
            font-size: 35px;
            color: white;
        }

        .installationName{
            font-size: 22px;
            color: white;
            margin-left: 8px;
        }
    }

    #user-menu {
        height: $toolbarHeight;
        padding: 0;

        md-menu-item .md-button {
            font-size: 15px;

            md-icon {
                margin: auto 16px auto 0;
            }
        }

        md-menu-content {

            &.md-menu-bar-menu {

                &.md-dense {
                    padding: 8px 0;

                    .md-button,
                    md-menu-item {
                        height: 48px;
                        line-height: 48px;
                    }
                }
            }
        }

        .user-button {
            margin: 0;
            padding: 0 16px;
            height: $toolbarHeight;
            text-transform: none;

            .avatar-wrapper {
                position: relative;

                .status {
                    position: absolute;
                    bottom: -3px;
                    right: 5px;
                }
            }

            .username {
                margin: 0 12px 0 8px;
            }
        }
    }

    #quick-panel-toggle:hover{
        cursor: pointer;
        background: rgba(255,255,255,0.2);
    }

    #language-menu {
        height: $toolbarHeight;

        .language-button {
            min-width: $toolbarHeight;
            height: $toolbarHeight;
            margin: 0;
            padding: 0 16px;
            border-radius: 0;

            .iso {
                text-transform: uppercase;
            }

            .flag {
                margin-right: 8px;
            }
        }
    }

    #quick-panel-toggle {
        width: $toolbarHeight;
        height: $toolbarHeight;
        margin: 0;
        border-radius: 0;
    }

    .toolbar-separator {
        height: $toolbarHeight;
        width: 1px;
        background: rgba(0, 0, 0, 0.12);
    }
}

#language-menu-content {

    md-menu-item {

        .flag {
            margin-right: 16px;
        }
    }
}

#language-message {
    min-height: 88px;
    max-width: 600px;

    .md-toast-content {
        height: 90px;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #toolbar {
        .toolbarTitle{
            .installationName{
                font-size: 18px;
            }
        }

        .button-cajeros{
            min-width: auto;
        }

        #user-menu {

            .user-button {
                min-width: 64px;
                padding: 0 2px 0 10px;
            }
        }
    }
}

#second-toolbar{
    height: $secondToolbarHeight;

    .section-name{
        line-height: 46px;
        font-size: 30px;
        text-align: center;
    }

    #navigation-toggle {
        width: $secondToolbarHeight;
        min-width: $secondToolbarHeight;
        height: $secondToolbarHeight;
        margin: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 0;
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {

    #second-toolbar{

        .section-name{
            /*margin-right: $secondToolbarHeight;*/
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 100%;
        }
    }
}

navigation-search{
    position:absolute;
    top: 3px;
    left: 3px;


    md-item-template{
        font-size: 15px;
        color: black;  
        padding: 8px 0;

        md-icon{
            color: gray !important;
            margin: 0 10px;
        }

        >span{
            align-self: center;
        }
    }

    input{
        transition: background 0.25s;

        &:focus{
            background: deepskyblue;
        }
    }

    md-autocomplete{

        md-autocomplete-wrap {
            button {
                align-self: center;
            }

            input#navSearchInput{
                background: transparent;
            }

            input#navSearchInput:focus{
                background: -webkit-linear-gradient(left, deepskyblue, transparent); /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(right, deepskyblue, transparent); /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(right, deepskyblue, transparent); /* For Firefox 3.6 to 15 */
                background: linear-gradient(to right, deepskyblue, transparent); /* Standard syntax */
            }
        }
    }
}