#unprovisioned {

    .Activo{
        border-left:7px solid #4CAF50;
    }

    .Inactivo{
        border-left:7px solid #F44336;
    }

    .Boton{
        border-left:7px solid orange;
    }

    .Bloqueado{
        border-left:7px solid #3e64ff;
    }

    .Desconocido{
        border-left:7px solid black;
    }

    .fila{}

    .fila:hover{
        background-color: #e4e4e4;
    }

    .filaBloqueada{
        background-color: #d4d4d4;
    }

    .botonCrear{
        position: absolute;
        z-index: 800;
        padding: 10px;
    }

    input.ng-invalid{
        background-color: pink;
    }

    input.ng-valid{
        background-color: lightgreen;
    }

    input.ng-pristine, input{
        background-color: #f7f7f7; 
    }

    input{
        transition:background-color 0.5s;
    }

    md-select{
        transition:background-color 0.5s;
        background-color: #f7f7f7; 
    }

    .container{
        min-height: 280px;
        margin: 1em;

        >div{
            margin: 1em;
        }

        h5{
            margin: 0;
        }
    }

    md-checkbox{
        margin-top:16px;
    }

    .icono-flecha{
        width: auto;
        height: auto;
        font-size: 4em;
        line-height: 64px;
    }

    .buscador{
        md-input-container{
            margin: 0;

            .md-errors-spacer{
                display: none;
            }
        }

        .pagination-button{
            min-width: 36px;
            font-size: 30px;
            padding: 0;
            
            span {
                margin: 0;
            }
        }

        >div:first-child{
            /*background: darkblue;*/
            border-radius:3em;

            input{
                border-radius: 10px 3em 3em 10px;
            }

            md-icon{
                color: white;
                margin: auto 5px;
            }
        }

        span{
            margin-left: 3px;
            margin-right: 8px;
        }
    }

    table{
        min-width:100%;
        border-collapse: collapse;

        th,td{
            min-width: 80px;
            padding: 0 1em;
        }

        td{
            border-top: 1px solid gray;
        }

        .column-title {
            cursor: pointer;

            &:after {
                position: relative;
                font-family: 'icomoon';
                font-weight: normal;
                margin-left: 10px;
                top: 2px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.54);
            }
        }

        .ord-no {

            &:after {
                content: '\eaa8';
            }
        }

        .ord-asc{

            &:after {
                content: '\e718';
            }
        }

        .ord-desc{

            &:after {
                content: '\e715';
            }
        }
    }

    .icono-tarjeta{
        font-size: 40px;
        align-self: center;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .lang-select{
        *:first-child{
            transform: none;
            -webkit-transform: none;
        }
    }
}

#editarHabitacion {
    md-checkbox {
        margin-left: 0;
        margin-right: 0;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    .page-layout.simple.fullwidth > .content {
        padding: 2px;
    }

    #editarHabitacion{
        md-dialog-content{
            padding:1px;
        }

        .container{
            min-height: 0;
            margin: 4px;

            >div{
                margin: 4px;
            }
        }

        md-tab-item{
            padding: 0;

            span{
                white-space: normal;
            }
        }
    }
}