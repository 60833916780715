#contabilidad{
    md-list{
        .md-list-item-text{
            margin:7px;
        }
        md-list-item{
            min-height: 0px;
        }
        md-list-item.md-3-line:hover, md-list-item.md-1-line:hover{
            background-color: lightgray;
        }
    }

    .pkgOper{
        border-bottom: 1px solid rgb(239,108,0);
        margin: 2px 0;
    }

    .pkgOper:hover{
        background-color: lightgray;
    }

    md-datepicker{
        margin-right: 0;
        padding-right: 0;
        background-color: transparent;
    }

    .md-1-line{
        h3{
            margin: auto;
        }
    }

    .total{
        *{
            font-weight: bolder;
        }
    }

    table{
        /*width: 100%;*/
        margin: 0 auto;
        border-spacing: 0;

        tr:hover{
            td:first-child{
                background-color: gray;
            }
            td{
                background-color: lightgray;
                cursor: pointer;
            }
            td:last-child{
                background-color: steelblue;
            }
            
            td.refund-td {
                background-color: #8b000073 !important;
            }
            
            td.cortesy-td {
                background-color: #adff2f73 !important;
            }
            
            td.balance-td {
                background-color: #b8860b73 !important;
            }
        }

        td{
            padding: 10px 8px;
            text-align: center;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        thead tr {
            background: black !important;
        }
        
        th{
            /*padding: 14px 15px;*/
            vertical-align: middle;
            background-color: black;
            color: white;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        
        th, td {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
        }

        tr:last-child, td:last-child, tr:nth-last-child(2){
            background-color: lightsteelblue;
        }

        tr:last-child:hover, tr:nth-last-child(2):hover{
            td:first-child{
                background-color: gray;
            }
            td{
                background-color: lightsteelblue;
                cursor: initial;
            }
            td:last-child{
                background-color: steelblue;
            }
        }

        td:first-child{
            background-color: lightgray;
        }

        td.bold{
            font-weight: bold;
        }

        td.zero{
            color: orangered;
        }
        
        td.refund-td {
            background-color: #8b000045;
        }
        
        td.cortesy-td {
            background-color: #adff2f45;
        }
        
        td.balance-td {
            background-color: #b8860b45;
        }

        thead {
            tr {
                display: block;
                position: relative;
            }
        }
        tbody {
            display: block;
            overflow: auto;
            width: 100%;
            /*height: calc(100vh - 300px);*/
        }
    }

    .ind-checkbox *{
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */    
    }
}

.md-datepicker-input-mask-opaque{
    background-color: transparent;
}