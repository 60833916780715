#mantenimiento{

}

#swap-mac{
    md-input-container{
        padding-bottom: 0;

        .md-errors-spacer{
            display: none;
        }
    }

    .swap-icon{
        font-size: 36px;
        width: 36px;
        height: 36px;
        line-height: 36px;
    }
}

#devices{
    table{
        border-collapse: collapse;
        width: 100%;
    }

    tr:nth-child(even){
        background-color: #f2f2f2
    }

    th, td {
        text-align: left;
        padding: 4px;
    }
    
    a {
        cursor: pointer;
    }
}

#updates{
    table{
        max-width: max-content;
        border-collapse: collapse;

        th, td{
            overflow: hidden
        }
        
        td {
            border-bottom: 1px solid gray;
            
            md-checkbox {
                margin-bottom: 0;
                margin-left: 12px;
            }
        }
    }

    th:first-of-type, td:first-of-type{
        min-width: 10px;
    }

    .Inactivo{
        background: #F44336;
    }

    .Activo{
        background: #4CAF50;
    }

    .Desconocido{
        background: black;
    }

    .clickable{
        cursor: pointer;
        text-decoration: underline;

        &:hover{
            background: lightslategrey;
        }
    }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    #devices{
        max-height: -webkit-fill-available;
        max-width: -webkit-fill-available;
    }
}