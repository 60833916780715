#principal{
    /*max-height:calc(100vh - 64px);*/

    .buscador{
        border: 2px solid gray;
        width: 100%;
        border-radius:2em;
        position: relative;
        background: whitesmoke;

        .md-button{
            position: absolute;
            top: -5px;
            left: -5px;
            margin:0;
        }

        md-input-container {
            /*background-color: #f7f7f7;*/
            margin: auto 40px;
            padding: 0;

            .md-errors-spacer{
                display: none;
            }
        }
    }

    .iconoSidenav{
        position: absolute;
        right: 0px;
    }

    .cabeceraTexto{
        font-size: 35px;
        color: white;
    }

    .hide-tab-label{
        > md-tabs-wrapper{
            display: none;
        }
    }

    .group-box{
        border: 1px solid;
        margin: 2px;
        min-width: fit-content;

        .group-box-title{
            text-align: center;
            margin-bottom: 4px;
            font-size: 16px;
            text-transform: uppercase;
            height: 24px;
            line-height: 24px;
        }

        .md-button{
            margin: 2px;
            font-size: 14px;
            line-height: 14px;
            padding: 6px;
            text-align: left;
            min-width: fit-content;
        }
    }

    .simpleList{
        /*        position: absolute;
                background: whitesmoke;
                top: 8px;
                bottom: 8px;
                left: 8px;
                right: 8px;
                z-index: 3;
                transition: transform 0.5s;
                transform-origin: left bottom;*/

        > .stb{
            margin: 3px;
            padding: 2px 4px 2px 2px;
            border: 1px solid black;
            border-radius: 1em 6px 6px 1em;
            max-width: 250px;
            min-width: 70px;
            position: relative;

            .stateLed{
                /*position: relative;*/
                width: 20px;
                height:20px;
                border-radius: 50%;
            }

            .Inactivo{
                background: #F44336;
            }

            .Activo{
                background: #4CAF50;
            }

            .Boton{
                background: orange;
            }

            .Bloqueado{
                background: #3e64ff;
            }

            .Desconocido{
                background: black;
            }

            span{
                margin-left: 2px;
            }
        }

        .checkout{
            background: lightgray;
            cursor: pointer;
        }

        .checkin{
            background: #fefefe;
            cursor: pointer;
        }

        .buscandoAp {
            background: #a9fbff;
        }
    }

    .notification-incidences{
        &::after {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #32b2ec;
            position: absolute;
            border-radius: 50%;
            top: 1px;
            right: 1px;
            animation: blue-bg-anim 1s linear 2s infinite alternate;
            z-index: 999;
        }

        @keyframes blue-bg-anim{
            0% {background-color: #32b2ec;}
            100% {background-color: #354388;}
        }

        &.stb {
            padding-right: 14px;
        }
    }

    .notification-prevents {
        &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #ea1e63;
            position: absolute;
            border-radius: 50%;
            top: 1px;
            right: 1px;
            animation: pink-bg-anim 1s linear 2s infinite alternate;
            z-index: 999;
        }

        @keyframes pink-bg-anim{
            0% {background-color: #ea1e63;}
            100% {background-color: #6d0e2e;}
        }

        &.stb {
            padding-right: 14px;
        }

        &.notification-incidences::before {
            top: 12px;
        }

        &.stb.notification-incidences::before {
            top: unset;
            bottom: 1px;
        }
    }

    .flipButton{
        z-index:999;
        opacity: 0.3;
        transition: opacity 0.25s;
    }

    .flipButton:hover{
        opacity: 1;
    }

    md-sidenav{
        overflow: visible;

        md-content{
            position: absolute;
            z-index: 2;
            top: 64px;
            bottom: 0;
            background: white;
            right: 0;
            left: 0;
            border-radius: 0;
        }

        .sidenavServicios{
            position: absolute;
            top: 62px;
            bottom: 0;
            left: -3em;
            right: 0;
            border-left: 3em solid #2d323e;
            border-top: 2px solid #2d323e;
            background: wheat;
            transition: all 0.5s;
            overflow: visible;

            md-list-item{
                z-index: 0;
            }

            .md-subheader{
                background: transparent;
            }

            >div{
                position: absolute;
                top: 0;
                bottom: 0;
                left: -3em;
                width: 3em;

                md-icon{
                    color: whitesmoke;
                    transition: all 0.5s;
                }
            }
        }

        .sidenavServicios:hover{
            transform: translateX(calc(40px - 100%));

            >div{                
                md-icon{
                    transform: rotateZ(180deg);
                }
            }
        }
    }

    /*    .md-padding{
            min-height: 400px;
        }*/

    md-tabs{
        .completeList{
            margin: 1px;
            width: 275px; 
            min-height: auto;
            border: 1px solid lightgray;
            border-radius: 1em 0 0 1em;
            overflow-x: hidden;

            h3{
                margin: auto 0 auto 5px;
            }

            >:not(.lockIcon){
                z-index: 3;
                /*padding-left: 0;*/
                padding-left: 5px;
                height: 100%;
            }

            .lockIcon{
                position: absolute;
                right: 5px;
                top: 5px;
                bottom: 5px;
                z-index: -1;

                *{
                    font-size: 26px;
                    height: 26px;
                    width: 26px;
                    line-height: 100%;
                    color: rgba(197,197,197,0.6);
                    margin-left: 4px;
                }
            }
        }

        .completeList-mini{
            width: 150px !important;
        }

        .locked{
            background: -webkit-linear-gradient(left, #3e64ff, transparent);
            background: -o-linear-gradient(right, #3e64ff, transparent);
            background: -moz-linear-gradient(right, #3e64ff, transparent);
            background: linear-gradient(to right, #3e64ff, transparent);
        }

        .checkout{
            background: lightgray;
        }

        .checkin{
            background: #fefefe;
        }

        .buscandoAp {
            background: #a9fbff;
        }

        .stateLed{
            /*position: absolute;*/
            top:10px;
            left:10px;
            width: 20px;
            height:20px;
            border-radius: 50%;
        }

        .Inactivo button {
            /*background: #F44336;*/
            border-left: 10px solid #F44336;
        }

        .Activo button {
            /*background: #4CAF50;*/
            border-left: 10px solid #4CAF50;
        }

        .Boton button {
            border-left: 10px solid orange;
        }

        .Desconocido button {
            border-left: 10px solid black;
        }

        .Bloqueado button {
            /*background: #3e64ff;*/
            border-left: 10px solid #3E64FF;
        }
    }

    .wdgt{
        margin: 4px;
        border-radius: 16px 4px;
        padding: 8px 14px;
        cursor: pointer;

        .h4{
            text-align: center;
        }

        h1{
            margin: inherit;
        }
    }

    .wdgt-marcado{
        animation: border-animation 1s linear infinite alternate;
    }

    @keyframes border-animation {
        0%   {border: 3px solid yellow;}
        100%  {border: 3px solid #58570c;}
    }

    .wdgt-idiomas{
        img{
            max-height: 40px;
            max-width: 40px;

            margin: 3px;
        }
    }

    .orange-bg{
        background-color: rgb(224, 113, 14) !important;
    }

    md-badge{
        margin: 0 0 0 3px;
        /*background-color: #4288bc;*/
        color: white;
        padding: 3px 6px;
        border-radius: 4px;
    }
}

#principal-details{
    .strong{
        font-weight: bold;
    }

    .underline {
        text-decoration: underline;
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {
    #principal-details{
        md-dialog-actions {
            min-height: fit-content;
            padding: 4px 16px;
            flex-wrap: wrap;

            .md-button {
                margin: 4px;
            }
        }
    }
}

.cabecera {
    height: 64px;
    background-image: url('/assets/images/backgrounds/header-bg.png');
    background-size: cover;
    /*background-color: #039BE5;*/
    padding: 10px;

    font-size: 35px;
    color: white;

    display: none;//Mostrar o no
}

._background_{
    background: none !important;

    md-content{
        border-radius: 1em;
        background-color: rgba(255, 255, 255, 0.85);
    }

    md-tabs, ms-form-wizard, .dataTables_wrapper {
        border-radius: 0.5em;
    }

    .dataTables_wrapper {
        background-color: white;
    }
}

.hint {
    /* Position the hint */
    /*position: absolute;*/
    left: 2px;
    right: auto;
    bottom: 7px;
    /* Copy styles from ng-messages */
    font-size: 12px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    /* Set our own color */
    color: grey; 
}

get-translate-json{
    position: fixed;
    right: 0;
    top:0;
    z-index: 99999;

    md-icon{
        transition: color 0.25s;
        cursor: pointer;
    }

    md-icon:hover{
        color:white;
    }
}

.click-gif{
    width: 25px;
    height: 25px;
}

::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 1em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #777; 
}

stb-screenshot {
    cursor: pointer;
    background-color: black;

    > div {
        position: relative;
        min-height: 50px;
        min-width: 50px;

        > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(72, 72, 72, 0.4);
            cursor: progress;

            md-progress-circular {

                .md-left .md-half-circle {
                    border-left-color: white !important;
                    border-top-color: white !important;
                }

                .md-right .md-half-circle {
                    border-right-color: white !important;
                    border-top-color: white !important;
                }
            }

        }
    }
}

.signal-meter-container {
    height: 10px;
    min-width: 50px;
    overflow: hidden;
    border-radius: 2em;
    background-color: #dcdcdc;
    display: inline-block;

    .signal-meter {
        min-height: 100%;

        &.good {background-color: #16a085;}
        &.bad {background-color: #e74c3c;}
        &.ok {background-color: #f1c40f;}
    }
}