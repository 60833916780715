#administradorCajeros{

    .copy-button{
        min-width: 0;
    }

    .input-copy{
        opacity: 0;
        position: absolute;
        width: 1px;
        height: 1px;
        pointer-events: none;
    }

    .blue {
        background: #003180;
        border-radius: 7px;
        font-weight: bold;
        padding: .5em;
        color: white;
    }

    .blueDark {
        background: #233056;
        border-radius: 7px;
        font-weight: bold;
        padding: .5em;
        color: white;
    }

    .cajeros {
        display: inline-block;
        text-align: center;

        div {
            border: 3px solid black;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            background: blue;
            color: white;
            font-size: 1.3em;
            font-weight: bold;
            margin: 2em;
            width: 35%;
        }
        a {
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            padding: 2em;
        }
    }

    .dates {
        text-align: center;

        *{
            background: none;
        }
    }

    .disponibilidad{
        md-switch{
            margin: 0 !important;
            display: inline-block;
            vertical-align: middle;
        }

        span {
            display: inline-block !important;
            width: auto !important;
        }
    }

    .empty {
        background: #9d9696 !important;
        color: black;
    }

    .estado {
        border-radius: 7px;
        color: white;
        width: 200px !important;
        text-align: center;
        font-size: 1.5em;
        font-weight: bold;
        padding: .5em;
    }

    .green {
        border-radius: 7px;
        font-weight: bold;
        padding: .5em;
        background: green;
        color: white;
        text-transform: uppercase;
    }

    .info {
        font-size: 1.3em;
        padding: 0 1em;

        span{
            text-align: center;
        }
    }

    .log {
        margin: 0 2em;
    }

    .pagado {
        margin: .2em 0;
        border-radius: 7px;
        color: white;
        font-weight: bold;
    }

    .nopagado {
        width: 8%;
    }

    .nopagado-background {
        color: white;
        font-weight: bold;
        display: inline-block;
        border-radius: 7px;
        padding: .5em;
        width: 36%;
    }

    .pantallaCarga {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;

        h1,h3 {
            color: #000;
        }

        .cargaCentral {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .red {
        background: red;
    }

    .saldo {
        text-align: center;
        padding: 0 1em;

        .moneda{
            margin: 10px;
            position: relative;

            img {
                width: 100px;
            }

            .cantidad{
                background: darkred;
                font-size: 22px;
                color: white;
                border-radius: 1em;

                position: absolute;
                top: 0;
                right: 0;

                min-width: 36px;
                height: 36px;
                line-height: 36px;
            }
        }
    }

    .tolva {
        border-radius: 21px;
        background: #142297;
        color: white;
        display: inline-block;
        font-weight: bold;
        padding: .25em .5em;
        margin: .5em;
        text-align: center;
        width: auto;
    }

    .trampilla md-switch{
        margin: 0 !important;
        display: inline-block;
        vertical-align: middle;
    }

    .trampilla span {
        display: inline-block !important;
        width: auto !important;
    }

    .actions{
        padding: 0 1em;
    }

    .content-table{
        max-height: 200px;
    }
}

#dispensar-mandos, #pagar-mandos{
    md-dialog-content{
        padding:8px 16px;
    }
}

#cajeros-logs {
    .list {
        margin: 0 16px;
        font-size: 18px;
    }
}