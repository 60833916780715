#gestionCompras{
    md-badge{
        margin: 0 0 0 8px;
        /*background-color: #259b24;*/
        color: white;
        padding: 3px 6px;
        border-radius: 4px;
    }

    .uploadContainer{
        border: 2px solid lightgrey;
        border-radius: 10px;
    }

    .input_oculto{
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
    }

    .canal-list-item{
        min-width: 375px;
        transition: background 0.25s;
    }

    .canal-list-item:hover{
        background: lightgray;
    }

    .pest{
        border: 1px solid lightgray;
        padding: 5px;
    }

    md-list-item{
        h3, h4{
            white-space: normal;
            /*max-height: 32px;*/
        }
    }

    .asig-list{
        height:100%;

        .item{
            max-height: 30px;
        }
    }

    .listaCanales{
        h4{
            margin: 5px;
        }

        margin: 5px;
        border: 1px solid gray;
        border-radius: 5px;
        cursor: move;

        background: white;
    }

    .listaCanales:hover{
        background: lightgray;
    }

    .lsta{
        min-width: 300px;
        max-width: 500px;
    }

    .lsta-highlight{
        background: yellow;
        height: 29px;
    }

    .tab{
        min-height: 600px;
    }

    .mini{
        width: 20px;
        height:20px;
        min-height: 20px;
    }

    md-list{
        .md-list-item-text{margin:7px;}
        md-list-item{min-height: 0px;}
        md-list-item:hover{background-color: lightgray;}

        .Balance{
            background-color: orange;
        }

        .Cash{
            background-color: green;
        }

        .Cortesy{
            background-color: greenyellow;
        }

        .Card{
            background-color: #4ea7e6;
        }

        .None{
            background-color: gray;
        }

        .PendingGive{
            background-color: orchid;
        }

        .PendingReceive{
            background-color: yellow;
        }

        .Paypal{
            background-color: cyan;
        }

        .idAccountant{
            min-height: 88px;
            padding-right: 5px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            align-self: stretch;
        }

        .ingreso{
            color:green;
        }
        .gasto{
            color:red;
        }
        .no{
            display: none;
        }
    }

    .comentario{
        /*color: gray;*/
        font-size: 12px;

        background-color: #ffc621;

        background: -webkit-linear-gradient(left, rgba(255,198,33,1), rgba(0,0,0,0));
        background: -o-linear-gradient(right, rgba(255,198,33,1), rgba(0,0,0,0));
        background: -moz-linear-gradient(right, rgba(255,198,33,1), rgba(0,0,0,0));
        background: linear-gradient(to right, rgba(255,198,33,1), rgba(0,0,0,0));

        border-radius: 15px;
        padding: 5px;
    }

    md-datepicker{
        margin-right: 0;
        padding-right: 0;
        background-color: transparent;
        transition: opacity 0.25s;
    }

    .disabled{
        opacity: 0;
    }

    ng-include{
        overflow: auto;
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    
    #gestionCompras {        
        md-input-container{
            padding: 0;
            margin-bottom: 0;
        }
        
        md-dialog-content{
            padding: 8px 16px;
        }
    }
}

.md-datepicker-input-mask-opaque{
    background-color: transparent;
}

//*************** Concepto *******************
._Balance{
    border-left: 5px solid darkgoldenrod;
    .idAccountant{
        background-color: darkgoldenrod;
    }
}

._Package{
    border-left: 5px solid darkgreen;
    .idAccountant{
        background-color: darkgreen;

        span,md-icon{
            color:lightgray;
        }
    }
}

._Cancellation{
    border-left: 5px solid darkgrey;
    .idAccountant{
        background-color: darkgrey;
    }
}

._ChangeRoom{
    border-left: 5px solid darkcyan;
    .idAccountant{
        background-color: darkcyan;
    }
}

._Refund{
    border-left: 5px solid darkred;
    .idAccountant{
        background-color: darkred;

        span,md-icon{
            color:lightgray;
        }
    }
}

._Service{
    border-left: 5px solid indigo;
    .idAccountant{
        background-color: indigo;

        span,md-icon{
            color:lightgray;
        }
    }
}

._Wallet{
    border-left: 5px solid orangered;
    .idAccountant{
        background-color: orangered;

        span,md-icon{
            color:lightgray;
        }
    }
}

._Voip{
    border-left: 5px solid darkmagenta;
    .idAccountant{
        background-color: darkmagenta;

        span,md-icon{
            color:lightgray;
        }
    }
}

//**************** Modo de pago ***************
.Balance_{
    background-color: orange;
}

.Cash_{
    background-color: green;
}

.Cortesy_{
    background-color: greenyellow;
}

.Card_{
    background-color: #4ea7e6;
}

.None_{
    background-color: gray;
}

.PendingGive_{
    background-color: orchid;
}

.PendingReceive_{
    background-color: yellow;
}

.Paypal_{
    background-color: cyan;
}