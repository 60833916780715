#properties{

    table {
        width: 100%;

        input {
            width: 100%;
        }

        td:last-of-type {
            width: 1px;
            white-space: nowrap;
        }
        
        .md-button {
            margin: 0;
        }
    }
}