#modelos{    
    .md-3-line{
        padding-left: 20px;
        margin:10px 0;
        border: 1px solid lightgray;

        align-items: center;
        min-height: auto;
        padding: 1em;

        .md-avatar{
            border-radius: initial;
            height: auto;
            margin: 8px 16px 8px 0;
            align-self: center;
        }

        .md-list-item-text{
            h4{
                white-space: normal;
            }
        }
    }

    .md-3-line:hover{
        background-color: lightgray;
    }

    .features{
        > md-icon{            
            font-size:40px;
            height: 40px;
            width: 40px;
            line-height: 40px;
            opacity: 0.4;
        }

        > md-icon:hover{
            opacity: 1;
        }
    }

    .hint{
        position: initial;
        margin-top: 10px;
    }
}

#modelos_versiones{
    .version{
        margin: 5px;
        padding: 10px;
        border-radius: 5px;
        background-color: rgb(45,50,62);
        color: rgba(255,255,255,1);
        position:relative;

        .versionName{
            margin: 0 1em;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .md-icon-button{
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
        }

        .icon-star{
            color: goldenrod; 
        }

        .md-select-value{
            *{
                color: white;
            }
        }

        .deleteButton{
            padding: 2px;
            cursor: pointer;

            transition:border-radius 0.25s;
        }

        .deleteButton:hover{
            border-radius: 2em;
        }
    }

    md-dialog-actions{
        padding:0;
    }

    md-progress-linear{
        margin: 0 2em;
    }
}

#import-modelos{

    .modelo{
        min-width: 280px;
        transition: background 0.25s;

        md-checkbox{
            margin-bottom: 0;
            margin-left: 1em;
        }
    }

    .modelo:hover{
        background: lightgray;
    }

    md-list-item{
        transition: background-color 0.25s;

        h4{
            white-space: normal;
            max-height: 32px;
            margin: 8px 0;
        }
    }

    md-dialog-content p{
        background: darkorange;
        padding: 5px;
        text-align: center;
    }
}